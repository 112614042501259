<template>
  <div v-if="starterKitId !== ''" class="starter-kit-message">
    <p class="starter-kit-message__title">
      <el-icon class="starter-kit-message__icon"><Warning /></el-icon>
      {{ $t('components.starter-kit-message.title') }}
    </p>
    <p class="starter-kit-message__subtitle">{{ $t('components.starter-kit-message.starterKitAvailable') }}</p>
    <el-button plain class="starter-kit-message__cta" @click.prevent="onGoToStarterKit">
      {{ $t('components.starter-kit-message.goto') }}
      <el-icon><EcSmArrowLinkRight /></el-icon>
    </el-button>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useAppStore } from '@/store/app-store'
import { navigateToProductDetails } from '@/router'

const appStore = useAppStore()

const starterKitId = computed(() => {
  return appStore.starterKitId.toString()
})

const onGoToStarterKit = () => {
  navigateToProductDetails(starterKitId.value)
}
</script>
