import axios, { AxiosPromise } from 'axios'

export default {
  getPassKey(): AxiosPromise {
    return axios({
      method: 'GET',
      url: '/shop-api/dts/passKey',
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
}
