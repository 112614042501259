<template>
  <div class="cart-terms" :class="{ 'cart-terms--mobile-version': props.isMobile }">
    <div class="cart-terms__conditions">
      <div class="cart-terms__condition">
        <el-checkbox v-model="termsAgreed" :size="checkboxSize" :disabled="isCheckboxDisabled">
          <slot>
            {{ $t('components.cart-summary.terms-label-1') }}
            <a @click.prevent="$emit('show-terms-dialog')">{{ $t('components.cart-summary.terms-cta') }}</a>
            {{ $t('components.cart-summary.terms-label-2') }}
          </slot>
        </el-checkbox>
      </div>
      <div class="cart-terms__condition">
        <el-checkbox v-model="returnsAgreed" :size="checkboxSize" :disabled="isCheckboxDisabled">
          <slot>
            {{ $t('components.cart-summary.returns-label') }}
          </slot>
        </el-checkbox>
      </div>
    </div>
    <el-button
      type="primary"
      :disabled="isGoToCheckoutDisabled"
      @click="$emit('go-to-checkout')">
      {{ $t('button.checkout') }}
    </el-button>
    <el-button
      type="default"
      :disabled="isContinueShoppingDisabled"
      @click="navigateToHome">
      {{ $t('button.continue-shopping') }}
    </el-button>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { useCartStore } from '@/store/cart-store'
import { navigateToHome } from '@/router'
import { onWindowResizeEvent } from '@/composables/use-resize'

type CartTerms = {
  isMobile: boolean,
}

const props = withDefaults(defineProps<CartTerms>(), {
  isMobile: false,
})

const cartStore = useCartStore()

const emits = defineEmits(['show-terms-dialog', 'go-to-checkout'])

const termsAgreed = ref(false)
const returnsAgreed = ref(false)
const windowWidth = ref(window.innerWidth)

const isGoToCheckoutDisabled = computed(() => {
  return !termsAgreed.value || !returnsAgreed.value || cartStore.loadingValidatePrice || cartStore.showInvalidItemsMessage
})

const isContinueShoppingDisabled = computed(() => {
  return cartStore.loadingValidatePrice || cartStore.showInvalidItemsMessage
})

const isCheckboxDisabled = computed(() => cartStore.showInvalidItemsMessage)

const checkboxSize = computed(() => {
  return windowWidth.value >= 768 ? 'default' : 'large'
})

onWindowResizeEvent(() => {
  windowWidth.value = window.innerWidth
})
</script>
