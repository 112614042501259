import i18n from '@/i18n/i18n'

// @ts-ignore
const { t, te } = i18n.global

export const navigateToUrl = (i18nKey: string = '') => {
  const url = getUrlByKey(i18nKey)
  if (url === '') return
  window.location.replace(url)
}

export const getUrlByKey = (i18nKey: string = '') => {
  let url = ''
  if (te(i18nKey)) {
    url = t(i18nKey)
  }
  return url
}
