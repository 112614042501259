<template>
  <ul class="checkout-summary-breakdown">
    <li class="checkout-summary-breakdown__item">
      <span class="checkout-summary-breakdown__label">{{ $t('components.checkout-summary-breakdown.subtotal') }}</span>
      <span class="checkout-summary-breakdown__price" v-number-format="{ number: checkoutStore.subtotal, locale: $i18n.locale }" />
    </li>
    <li class="checkout-summary-breakdown__item" v-if="isShowDiscount">
      <span class="checkout-summary-breakdown__label">{{ $t('components.checkout-summary-breakdown.discount') }}
        <p class="checkout-summary-breakdown__hint" v-for="discountReason in discountReasons">{{ translatedDiscountReason(discountReason) }}</p>
      </span>
      <span class="checkout-summary-breakdown__price" v-number-format="{ number: -discountAmount, locale: $i18n.locale }" />
    </li>
    <li class="checkout-summary-breakdown__item">
      <span class="checkout-summary-breakdown__label">{{ $t('components.checkout-summary-breakdown.shipping') }}</span>
      <span class="checkout-summary-breakdown__price">{{ $t('components.checkout-summary-breakdown.free') }}</span>
    </li>
    <template v-if="isFormPageShipping">
      <li class="checkout-summary-breakdown__item">
        <span class="checkout-summary-breakdown__label">{{ $t('components.checkout-summary-breakdown.taxes') }}</span>
        <span class="checkout-summary-breakdown__price checkout-summary-breakdown__price--disabled">
          {{ $t('components.checkout-summary.calculated-next-step') }}
        </span>
      </li>
    </template>
    <template v-else>
      <li v-for="taxItem in taxItems" class="checkout-summary-breakdown__item">
        <span class="checkout-summary-breakdown__label">{{ taxItem.label }}</span>
        <span class="checkout-summary-breakdown__price"
              v-number-format="{ number: taxItem.value, locale: $i18n.locale }" />
      </li>
    </template>
  </ul>
</template>

<script setup lang="ts">
import { useCheckoutStore } from '@/store/checkout-store'
import { FORM_PAGES_IDS } from '@/utils/constants'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

const checkoutStore = useCheckoutStore()
const { t, te } = useI18n()

const isShowDiscount = computed(() => {
  return [FORM_PAGES_IDS.PAYMENT, FORM_PAGES_IDS.REVIEW].includes(checkoutStore.formCurrentPageId) && discountAmount.value > 0
})

const taxItems = computed(() => {
  const taxItems = []
  const taxes = checkoutStore.taxes
  delete taxes['__typename']
  for (const [key, value] of Object.entries(taxes)) {
    taxItems.push({
      label: t('components.checkout-summary-breakdown.' + key),
      value: value,
    })
  }
  return taxItems
})

const discountAmount = computed(() => {
  return checkoutStore.discounts
    ?.map((discount: any) => discount.discount)
    ?.reduce((partialSum: number, discountAmount: number) => partialSum + discountAmount, 0)
})

const discountReasons = computed(() => {
  return checkoutStore.discounts?.map((discount: any) => discount.discountReason)
})

const isFormPageShipping = computed(() => {
  return checkoutStore.formCurrentPageId === FORM_PAGES_IDS.SHIPPING
})

const translatedDiscountReason = (discountReason: any) => {
  return te(`components.checkout-summary-breakdown.discountReason.${discountReason}`)
    ? t(`components.checkout-summary-breakdown.discountReason.${discountReason}`)
    : discountReason
}
</script>
