import gql from 'graphql-tag'

export const SUBMIT_ORDER = gql`
  mutation SUBMIT_ORDER ($createOrder : CreateOrder, $lang : String) {
    createOrder(createOrder : $createOrder, lang : $lang)
  }
`

export const VALIDATE_PRICE_CART = gql`
  mutation VALIDATE_PRICE ($priceValidation: PriceValidation) {
    validatePriceCart(priceValidation: $priceValidation) {
      productPriceItems {
        pricebookEntryId
        name
        productId
        unitPrice
        quantity
        displayUrl
        remark
      }
    }
  }
`

export const VALIDATE_PRICE_CHECKOUT = gql`
  mutation VALIDATE_PRICE ($priceValidation: PriceValidation) {
    validatePriceCheckout(priceValidation: $priceValidation) {
      tax {
        gstHst
        pst
        qst
      }
      subTotalPrice
      totalPrice
      productPriceItems {
        pricebookEntryId
        name
        displayUrl
        unitPrice
        quantity
        totalPrice
        discount
        discountReason
        remark
      }
    }
  }
`
