<template>
  <div id="checkout-summary-collapse" />
  <div class="checkout-summary" id="checkoutSummary" role="complementary" :aria-label="$t('accessibility.landmarks.checkout-summary')">
    <el-collapse v-model="collapsesOpen" class="checkout-summary__collapse">
      <el-collapse-item name="true">
        <template #title>
          <el-link class="checkout-summary__collapse-link">
            <span>{{ $t('components.checkout-summary.show-summary') }}</span>
            <el-icon :size="16">
              <ArrowDownBold v-if="isCollapseOpen" />
              <ArrowUpBold v-else />
            </el-icon>
          </el-link>
          <div v-if="!isCollapseOpen" class="checkout-summary__collapse__breakdown">
            <span class="checkout-summary__total-price__label">
              {{ $t('components.checkout-summary.total') }}
            </span>
            <span v-if="isFormPageShipping" class="checkout-summary__total-price checkout-summary__total-price--disabled">
              {{ $t('components.checkout-summary.calculated-next-step') }}
            </span>
            <span v-else class="checkout-summary__total-price"
                  v-number-format="{ number: checkoutStore.total, locale: $i18n.locale }" />
          </div>
        </template>
        <div>
          <el-divider></el-divider>
          <checkout-summary-items />
          <el-divider></el-divider>
          <checkout-summary-breakdown />
          <template v-if="isCollapseOpen">
            <el-divider></el-divider>
            <div class="checkout-summary__collapse__breakdown">
              <span class="checkout-summary__total-price__label">
                {{ $t('components.checkout-summary.total') }}
              </span>
              <span v-if="isFormPageShipping" class="checkout-summary__total-price checkout-summary__total-price--disabled">
                {{ $t('components.checkout-summary.calculated-next-step') }}
              </span>
              <span v-else class="checkout-summary__total-price"
                    v-number-format="{ number: checkoutStore.total, locale: $i18n.locale }" />
            </div>
          </template>
        </div>
      </el-collapse-item>
    </el-collapse>
    <div class="checkout-summary__list">
      <h2 class="checkout-summary__list__title">{{ $t('components.checkout-summary.title') }}</h2>
      <checkout-summary-items />
      <el-divider></el-divider>
      <checkout-summary-breakdown />
      <el-divider></el-divider>
      <div class="checkout-summary__total-price">
        <span class="checkout-summary__total-price__label">
          {{ $t('components.checkout-summary.total') }}
        </span>
        <span v-if="isFormPageShipping" class="checkout-summary__total-price__price checkout-summary__total-price__price--disabled">
          {{ $t('components.checkout-summary.calculated-next-step') }}
        </span>
        <span v-else class="checkout-summary__total-price__price"
              v-number-format="{ number: checkoutStore.total, locale: $i18n.locale }" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'
import { useCheckoutStore } from '@/store/checkout-store'
import { useI18n } from 'vue-i18n'
import { ArrowDownBold, ArrowUpBold } from '@element-plus/icons-vue'
import { FORM_PAGES_IDS } from '@/utils/constants'
import CheckoutSummaryItems from '@/components/CheckoutSummaryItems.vue'
import CheckoutSummaryBreakdown from '@/components/CheckoutSummaryBreakdown.vue'

const emits = defineEmits(['checkout-summary-mounted'])

const checkoutStore = useCheckoutStore()
const { t } = useI18n()

const collapsesOpen = ref([])

const isCollapseOpen = computed(() => {
  collapsesOpen.value.length === 1 ? checkoutStore.setCheckoutSummaryCollapseOpen(true) : checkoutStore.setCheckoutSummaryCollapseOpen(false)
  return collapsesOpen.value.length === 1
})

const isFormPageShipping = computed(() => {
  return checkoutStore.formCurrentPageId === FORM_PAGES_IDS.SHIPPING
})

onMounted(() => {
  emits('checkout-summary-mounted')
})
</script>
