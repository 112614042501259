<template>
  <div>
    <product-catalog-header />
    <product-catalog-items v-if="displayProducts"
                           :items="products" />
    <product-catalog-message v-else-if="displayMessage" />
<!--    <product-catalog-pagination v-if="displayPaginationControls" />-->
  </div>
</template>

<script setup lang="ts">
import { onBeforeMount, computed, h } from 'vue'
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import { useProductStore } from '@/store/product-store'
import ProductCatalogHeader from './ProductCatalogHeader.vue'
import ProductCatalogItems from './ProductCatalogItems.vue'
import ProductCatalogMessage from './ProductCatalogMessage.vue'
import ProductCatalogPagination from './ProductCatalogPagination.vue'

const { t } = useI18n()
const productStore = useProductStore()

const products = computed(() => {
  return productStore.products
})

const displayProducts = computed(() => {
  return products.value?.length > 0 && !productStore.loadingProducts
})

const displayMessage = computed(() => {
  return productStore.hasFetchedInitialProducts &&
    products.value?.length === 0 &&
    !productStore.loadingProducts
})

const displayPaginationControls = computed(() => !productStore.loadingProducts)

const showRequestFailedMessage = () => {
  ElMessage({
    type: 'error',
    duration: 0,
    zIndex: 3000, // on top of dialogs,
    showClose: true,
    message: h('div', { 'class': 'el-message__content' }, [
      h('div', null, t('notification.error-fetch-products.message')),
      h('a', { 'id': 'refreshPageLink' }, t('button.refresh-page')),
    ]),
  })

  const refreshPageLink = document.getElementById('refreshPageLink');
  if (refreshPageLink) {
    refreshPageLink.onclick = () => {
      location.reload()
    }
  }
}

const doFetchProducts = async() => {
  try {
    return productStore.fetchProducts()
  } catch (error) {
    throw error
  }
}

onBeforeMount(async () => {
  try {
    await doFetchProducts()
  } catch {
    showRequestFailedMessage()
  }
})
</script>
