<template>
  <shop-header></shop-header>
  <div id="main-content" role="main" :aria-label="$t('accessibility.landmarks.main')">
    <product-catalog-single-banner />
    <el-main v-loading="productStore.loadingProducts" class="shop-home">
      <product-catalog></product-catalog>
    </el-main>
  </div>
</template>

<script setup lang="ts">
import { useHead } from '@vueuse/head'
import { useProductStore } from '@/store/product-store'
import { getTranslatedTextByKey } from '@/utils/i18n'
import ShopHeader from '@/components/ShopHeader.vue'
import ProductCatalog from '@/components/ProductCatalog.vue'
import ProductCatalogSingleBanner from '@/components/ProductCatalogSingleBanner.vue'

const productStore = useProductStore()

const metaConfig = { // expose to unit tests
  title: getTranslatedTextByKey('seo.pages.home.title'),
  description: getTranslatedTextByKey('seo.pages.home.description'),
}

useHead({
  title: metaConfig.title,
  meta: [{
    name: 'description',
    content: metaConfig.description,
  }],
})
</script>
