<template>
  <div class="product-stock-status">
    <div class="product-stock-status__top">
      <el-icon class="product-stock-status__icon">
        <component :is="iconName" :class="`product-stock-status__icon ${iconClass}`" />
      </el-icon>
      <span class="product-stock-status__status">{{ $t(stockStatusKey) }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, toRef } from 'vue'
import { STOCK_STATUSES } from '@/utils/constants'
import { getStockStatus } from '@/utils/stock'

type ProductStockStatus = {
  showNotifyButton?: boolean,
  quantityAvailable: number,
  inventoryLevel: string,
}

const props = withDefaults(defineProps<ProductStockStatus>(), {
  showNotifyButton: false,
  quantityAvailable: 1,
  inventoryLevel: 'IN_STOCK'
})

const emits = defineEmits(['open-lightbox-notify-email'])

const propInventoryLevel = toRef(props, 'inventoryLevel')

const stockStatus = computed((): string => {
  return getStockStatus(propInventoryLevel.value)
})

const stockStatusKey = computed((): string => {
  if (stockStatus.value === STOCK_STATUSES.OUT_OF_STOCK) {
    return 'components.product-stock-status.stock-status.out-of-stock'
  } else if (stockStatus.value === STOCK_STATUSES.LIMITED_STOCK) {
    return 'components.product-stock-status.stock-status.limited-stock'
  } else {

  return 'components.product-stock-status.stock-status.in-stock'
  }
})

const iconName = computed((): string => {
  if (stockStatus.value === STOCK_STATUSES.OUT_OF_STOCK) {
    return 'CircleCloseFilled'
  } else if (stockStatus.value === STOCK_STATUSES.LIMITED_STOCK) {
    return 'WarningFilled'
  } else {

  return 'SuccessFilled'
  }
})

const iconClass = computed((): string => {
  if (stockStatus.value === STOCK_STATUSES.OUT_OF_STOCK) {
    return 'product-stock-status__icon--red'
  } else if (stockStatus.value === STOCK_STATUSES.LIMITED_STOCK) {
    return 'product-stock-status__icon--yellow'
  } else {

  return 'product-stock-status__icon--green'
  }
})
</script>
