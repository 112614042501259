<template>
  <el-dialog v-model="shouldShowDialog"
             class="dialog-session-timed-out el-dialog--no-header"
             width="90%"
             :title="$t('dialog.session-timed-out.title')"
             :show-close="false"
             :close-on-click-modal="false"
             :close-on-press-escape="false"
             :lock-scroll="true"
             @close="$emit('dialog-closed')">
    <div class="dialog-session-timed-out__top">
      <h3>{{ $t('dialog.session-timed-out.title') }}</h3>
      <p>{{ $t('dialog.session-timed-out.subtitle') }}</p>
    </div>
    <div class="dialog-session-timed-out__bottom">
      <el-button type="primary" @click="$emit('dialog-closed')">{{ $t('button.start-over') }}</el-button>
    </div>
  </el-dialog>
</template>

<script setup lang="ts">
import { computed } from 'vue'

type DialogSessionTimedOut = {
  showDialog: boolean,
}

const props = withDefaults(defineProps<DialogSessionTimedOut>(), {
  showDialog: false,
})

const emits = defineEmits(['dialog-closed'])

const shouldShowDialog = computed(() => props.showDialog ?? false)
</script>
