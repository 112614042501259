/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosStatic } from 'axios'

interface NotifyErrorParams {
  title: string;
  message: string;
  duration?: number;
}

const buildNotificationObject = (app: any, statusCode: number, error: any): NotifyErrorParams => {
  const message = error.response?.data?.message ? error.response.data.message : app.$t('notification.error.message-not-available').toString()
  return ![401, 0].includes(statusCode)
    ? {
        title: app.$t('notification.error.title') + ` - ${statusCode}`,
        message
      }
    : {
        title: app.$t('notification.session.expired.title').toString(),
        message: app.$t('notification.session.expired.message').toString(),
        duration: 5000
      }
}

export default (app: any, axios: AxiosStatic, error: any) => {
  if (!axios.isCancel(error)) {
    const statusCode = error && error.response ? error.response.status : 0
    app.$notify.error(buildNotificationObject(app, statusCode, error))
  }
  return Promise.reject(error)
}
