<template>
  <nav role="navigation" :aria-label="$t('accessibility.landmarks.navigation')" class="shop-header-menu__wrapper">
    <el-menu
      default-active="2"
      class="shop-header-menu"
      :class="{ 'shop-header-menu--open': isMenuOpen }"
      role="menu"
      tabindex="0"
    >
      <el-menu-item index="1" class="el-menu-item--blue" @click="navigateToPage('home')">
        {{ $t('header-menu.home') }}
      </el-menu-item>
      <el-sub-menu index="2" class="el-sub-menu--blue" :expand-close-icon="EcXsArrowBasicDown" :expand-open-icon="EcXsArrowBasicUp">
        <template #title>
          {{ $t('header-menu.help') }}
        </template>
        <el-menu-item index="2-1" @click="navigateToPage('cancellation')">
          {{ $t('header-menu.cancellation') }}
        </el-menu-item>
        <el-menu-item index="2-2" @click="$emit('menuClosed')">
          <a :href="getUrlByKey('header-menu.url-legal')" target="_blank" rel="noopener noreferrer">
            {{ $t('header-menu.legal') }}
          </a>
        </el-menu-item>
        <el-menu-item index="2-3" @click="$emit('menuClosed')">
          <a :href="getUrlByKey('header-menu.url-privacy')" target="_blank" rel="noopener noreferrer">
            {{ $t('header-menu.privacy') }}
          </a>
        </el-menu-item>
        <el-menu-item index="2-4" @click="navigateToPage('terms')">
          {{ $t('header-menu.terms') }}
        </el-menu-item>
      </el-sub-menu>
      <el-menu-item index="3" @click="navigateToPage('contact')">
        {{ $t('header-menu.contact-us') }}
      </el-menu-item>
      <el-menu-item index="4" @click="$emit('changeLanguage')">
        {{ $t('header-menu.other-language') }}
      </el-menu-item>
    </el-menu>
  </nav>
</template>

<script setup lang="ts">
import { navigateToHome, navigateToContact, navigateToCancellationPolicy, navigateToTermsConditions } from '@/router'
import { useI18n } from 'vue-i18n'
import { getUrlByKey } from '@/utils/navigation'
import { EcXsArrowBasicUp, EcXsArrowBasicDown } from '@ec/icons'

type ShopHeaderMenu = {
  isMenuOpen: boolean,
}

const props = withDefaults(defineProps<ShopHeaderMenu>(), {
  isMenuOpen: false,
})

const { t } = useI18n()
const emits = defineEmits(['changeLanguage', 'menuClosed'])

const navigateToPage = (pageName: string = '') => {
  emits('menuClosed')
  if (pageName === 'home') navigateToHome()
  if (pageName === 'cancellation') navigateToCancellationPolicy()
  if (pageName === 'terms') navigateToTermsConditions()
  if (pageName === 'contact') navigateToContact()
}
</script>
