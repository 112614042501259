<template>
  <el-header class="el-header--shop shop-header" :class="{ 'shop-header--menu-open': isMenuOpen }" role="navigation" :aria-label="$t('accessibility.landmarks.navigation')">
    <div class="shop-header__top hidden-xs-only">
      <div class="shop-header__inner">
        <div class="shop-header__top-left"></div>
        <div class="shop-header__top-right">
          <el-button size="small" @click="navigateToContact" link :text="true" :aria-label="$t('header.contact')">
            {{ $t('header.contact') }}
          </el-button>
          <el-button size="small" @click="changeLanguage" link :text="true" :aria-label="$t('header.other-language')">
            {{ $t('header.other-language') }}
          </el-button>
        </div>
      </div>
    </div>
    <div class="shop-header__bottom">
      <div class="shop-header__inner">
        <div class="shop-header__bottom-left">
          <div class="shop-header__logo">
            <router-link
              :to="{ name: routesName.SHOP_HOME }"
              class="el-link--icon"
            >
              <span class="icon icon-bell">
                <span class="sr-only">Bell</span>
              </span>
            </router-link>
            <el-divider direction="vertical" />
            <h1 class="h2 shop-header__title">
              <span>{{ $t('components.shop-header.title-1') }}</span>
              {{ $t('components.shop-header.title-2') }}
            </h1>
          </div>
        </div>
        <div class="shop-header__bottom-right">
          <el-badge
            :value="cartStore.count"
            :hidden="cartStore.count === 0">
            <router-link
              :to="{ name: routesName.SHOPPING_CART }"
              class="el-link--icon">
              <span class="sr-only">{{ $t('header.go-to-cart') }}</span>
              <ec-lg-cart />
            </router-link>
          </el-badge>
          <div class="shop-header__menu-icon hidden-sm-and-up" :class="{ 'shop-header__menu-icon--open': isMenuOpen }" @click="onMenuToggle">
            <span /><span /><span /><span />
          </div>
        </div>
      </div>
    </div>
  </el-header>
  <shop-header-menu :is-menu-open="isMenuOpen" @changeLanguage="changeLanguage" @menuClosed="onMenuClosed" />
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { ROUTES_NAME, navigateToContact } from '@/router'
import { useCartStore } from '@/store/cart-store'
import { getLanguage, saveLanguage } from 'rky-library-ui'
import { useI18n } from 'vue-i18n'
import ShopHeaderMenu from '@/components/ShopHeaderMenu.vue'
import { EcLgCart } from '@ec/icons'

const { locale } = useI18n({ useScope: 'global' })
const router = useRouter()
const routesName = ref(ROUTES_NAME)
const cartStore = useCartStore()

const isMenuOpen = ref(false)

const changeLanguage = () => {
  const toLang = getLanguage() === 'fr' ? 'en' : 'fr'
  saveLanguage(toLang)
  locale.value = toLang
  router.go(0)
}

const onMenuToggle = () => {
  if (isMenuOpen.value) {
    onMenuClosed()
  } else {
    onMenuOpened()
  }
}

const onMenuOpened = () => {
  isMenuOpen.value = true
}

const onMenuClosed = () => {
  isMenuOpen.value = false
  window.scrollTo(0, 0)
}
</script>
