<template>
  <el-dialog v-model="showDialog"
             class="dialog-checkout-inactivity el-dialog--no-header"
             width="90%"
             :title="$t('dialog.checkout-inactivity.title')"
             :show-close="false"
             :close-on-click-modal="false"
             :close-on-press-escape="false"
             :lock-scroll="true"
             @closed="$emit('dialog-closed')">
    <div class="dialog-checkout-inactivity__top">
      <h3>{{ $t('dialog.checkout-inactivity.title') }}</h3>
      <p>{{ $t('dialog.checkout-inactivity.subtitle') }}</p>
    </div>
    <div class="dialog-checkout-inactivity__countdown">
      <p class="dialog-checkout-inactivity__countdown__label">{{ $t('dialog.checkout-inactivity.time-left') }}</p>
      <p class="dialog-checkout-inactivity__countdown__time">{{ displayedRemainingTime }}</p>
    </div>
    <div class="dialog-checkout-inactivity__bottom">
      <p>{{ $t('dialog.checkout-inactivity.footer-message') }}</p>
      <el-button type="primary" @click="onContinueSession">{{ $t('button.continue-session') }}</el-button>
    </div>
  </el-dialog>
</template>

<script setup lang="ts">
import { computed, ref, watch, onMounted } from 'vue'
import { DateTime, Duration } from 'luxon'
const dateTimeNow = ref()
const dateTimeEnd = ref()
const timeoutTick = ref()

type DialogCheckoutInactivity = {
  timeoutMinutes: number,
}

const props = withDefaults(defineProps<DialogCheckoutInactivity>(), {
  timeoutMinutes: 10,
})

const emits = defineEmits(['continue-session', 'clear-session'])

const showDialog = ref(true)

const isTimeoutFinished = computed(() => {
  return dateTimeNow.value >= dateTimeEnd.value
})

const remainingTime = computed(() => {
  return dateTimeEnd.value.diff(dateTimeNow.value).toObject()
})

const displayedRemainingTime = computed(() => {
  return Duration.fromObject(remainingTime.value).toFormat('mm:ss')
})

const setTimeoutWatcher = () => {
  watch(dateTimeNow, () => {
    if (isTimeoutFinished.value) {
      clearInterval(timeoutTick.value)
      emits('clear-session')
    }
  })
}

const onContinueSession = () => {
  showDialog.value = false
  emits('continue-session')
}

onMounted(() => {
  showDialog.value = true
  dateTimeNow.value = DateTime.local()
  dateTimeEnd.value = DateTime.local().plus({ minutes: props.timeoutMinutes })
  timeoutTick.value = setInterval(() => {
    dateTimeNow.value = DateTime.local()
  }, 100)

  setTimeoutWatcher()
})
</script>
