import { getEnvironment } from '@/utils/environments'

const isFeatureEnabled = (...envs:string[]):boolean => {
  const environment = getEnvironment()

  if (!environment) return false

  return envs.includes(environment)
}

const PRODUCTS_SEARCH_ENABLED = () => isFeatureEnabled()
const VALIDATE_PRICE_CART_ENABLED = () => isFeatureEnabled('loc', 'int')

export {
  PRODUCTS_SEARCH_ENABLED,
  VALIDATE_PRICE_CART_ENABLED,
}
