<template>
  <div class="checkout-confirmation-message">
    <div v-if="submissionFailed">
      <ec-lg-error-circle class="ec-icon--error" />
      <h2 class="checkout-confirmation-message__title">{{ $t('components.checkout-confirmation-message.failure.title') }}</h2>
      <p class="checkout-confirmation-message__message"
        v-html="$t('components.checkout-confirmation-message.failure.message')"
      />

      <div class="checkout-confirmation-message__footer">
        <el-button
          type="primary"
          @click="onTryAgain">
          {{ $t('button.try-again') }}
        </el-button>
      </div>
    </div>

    <div v-else>
      <ec-lg-checkmark-circle class="ec-icon--check" />
      <h2 class="checkout-confirmation-message__title">{{ $t('components.checkout-confirmation-message.success.title') }}</h2>
      <p class="checkout-confirmation-message__message">{{ $t('components.checkout-confirmation-message.success.message-1') }}</p>
      <p class="checkout-confirmation-message__message checkout-confirmation-message__message--small">{{ $t('components.checkout-confirmation-message.success.message-2') }}</p>

      <div class="checkout-confirmation-message__footer">
        <el-button
          type="primary"
          @click="$emit('redirectToHome')">
          {{ $t('button.continue-shopping') }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { SUBMISSION_STATES } from 'rky-neo-form-lib-utils'
import { EcLgCheckmarkCircle, EcLgErrorCircle } from '@ec/icons'
import { ElMessage } from "element-plus";

type CheckConfirmationMessage = {
  formData: object,
  submissionState: string,
  submissionFailed: boolean,
}

const props = withDefaults(defineProps<CheckConfirmationMessage>(), {
  submissionState: SUBMISSION_STATES.NOT_SUBMITTED,
  submissionFailed: false,
})

const emits = defineEmits(['returnToOffering'])

const { t } = useI18n()

const onTryAgain = () => {
  emits('returnToOffering')
  createErrorToast()
}

const createErrorToast = () => {
  ElMessage({
    type: 'error',
    showClose: true,
    message: t('components.checkout-confirmation-message.failure.toast'),
    zIndex: 3000, // on top of dialogs,
    icon: EcLgErrorCircle,
    customClass: 'el-message--error',
    duration: 0,
  })
}
</script>
