<template>
  <div :key="componentKey" class="cart-items__list">
    <ul>
      <li v-for="item in cartStore.items">
        <div class="cart-items__list__desc">
          <router-link :to="`/product/${item.id}`"
                       class="cart-items__desc">
            <span class="sr-only">{{ $t('components.product-catalog-item.link-alt') }}</span>
            <product-image :attachments="item.attachments" :product-name="item.name" class="cart-items__desc__image" />
          </router-link>
          <div class="cart-items__list__text">
            <router-link
              :to="`/product/${item.id}`"
              class="cart-items__desc">
              <span class="cart-items__desc__title">{{ item.name }}</span>
            </router-link>
            <span class="cart-items__list__sku">{{ item.id }}</span>
          </div>
        </div>
        <product-quantity
          :quantity-selected="item.quantitySelected"
          :show-label="true"
          :is-small-layout="true"
          @change="(newQuantity) => cartStore.updateItemQuantity(item.id, newQuantity)">
        </product-quantity>
        <div class="cart-items__list__prices">
          <div>
            <label>{{ $t('components.cart-items.columns.price') }}</label>
            <span class="cart-items__price" v-number-format="{ number: item.price, locale: $i18n.locale }"></span>
          </div>
          <div>
            <label>{{ $t('components.cart-items.columns.total') }}</label>
            <span class="cart-items__price" v-number-format="{ number: getCartItemPriceTotal(item), locale: $i18n.locale }"></span>
          </div>
        </div>
        <el-button :icon="TrashIcon" class="cart-items__delete-button" @click="cartStore.removeItem(item.id)">
          <span class="sr-only">{{ $t('components.cart-items.delete-button-alt') }}</span>
        </el-button>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useCartStore } from '@/store/cart-store'
import { getCartItemPriceTotal } from '@/utils/pricing'
import ProductImage from '@/components/ProductImage.vue'
import ProductQuantity from '@/components/ProductQuantity.vue'
import TrashIcon from '@/components/icons/TrashIcon.vue'

const cartStore = useCartStore()
const { items } = storeToRefs(cartStore)

const componentKey = ref(0)

watch(items, () => {
  componentKey.value++
})
</script>
