<template>
<!--  <router-link-->
<!--    v-if="isClickable"-->
<!--    :to="`/product/`"-->
<!--    class="product-category product-category&#45;&#45;clickable">-->
<!--    Device-->
<!--  </router-link>-->
  <div class="product-category">
    {{ categoryName }}
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

type ProductCategory = {
  productFamily: string,
  isClickable: boolean,
}

const props = withDefaults(defineProps<ProductCategory>(), {
  productFamily: 'device',
  isClickable: true,
})

const { t, te } = useI18n()

const categoryName = computed(() => {
  const productFamily = props.productFamily.toLowerCase()
  if (te(`components.product-category.${productFamily}`)) {
    return t(`components.product-category.${productFamily}`)
  }
  return props.productFamily
})
</script>
