<template>
  <div v-if="show" class="unsupported-browser">
    <div class="banner-notification-grid-zone">
      <div class="banner-notification-grid">
        <div class="banner-icon">
          <em class="icon icon-outline-exclamation-a info" />
        </div>
        <div class="banner-message">
          <div class="title">
            {{ t('unsupported-browsers.title') }}
          </div>
          <div class="desc">
            {{ t('unsupported-browsers.message') }}
          </div>
        </div>
        <div class="banner-close-button">
          <i class="close-button icon icon-x" @click="hideMessage" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from '@vue/reactivity'
import { useI18n } from 'vue-i18n'
import { onMounted } from '@vue/runtime-core'

const show = ref(false)

const { t } = useI18n()

const hideMessage = () => {
  show.value = false
}

onMounted(() => {
  show.value = (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1)
})

</script>
