<template>
  <div class="product-details-images">
    <div class="swiper-container product-details-images__main" id="product-details-images__main">
      <div class="swiper-wrapper">
        <div v-for="(image, index) in displayedImages" :key="'image-' + index" class="swiper-slide">
          <img :src="getAttachmentUrl(image.url)" :alt="image.description" />
        </div>
      </div>
    </div>
    <div class="swiper-container product-details-images__thumbs" id="product-details-images__thumbs">
      <div class="swiper-wrapper">
        <div v-for="(image, index) in displayedImages" :key="'thumb-' + index" class="swiper-slide" :class="{ 'swiper-slide-active': index === 0 }">
          <img :src="getAttachmentUrl(image.url)" :alt="image.description" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, computed } from 'vue'
import { arrayMoveToIndex } from '@/utils/array'
import Swiper from 'swiper'
import { Swiper as SwiperType } from 'swiper/types'
import { Attachment } from '@/domain/attachment'
import { getAttachmentUrl } from '@/utils/attachment'

type ProductDetailsImages = {
  attachments: Attachment[],
}

const props = defineProps<ProductDetailsImages>()

let galleryThumbs: SwiperType
let galleryMain: SwiperType
const MAX_ATTACHMENTS_COUNT: number = 5

onMounted(async () => {
  initSwiper()
})

const displayedImages = computed(() => {
  if (!props.attachments || !props.attachments.length) return []
  let attachments = props.attachments.filter(attachment => attachment.contentType === 'Image')
  const defaultIndex = attachments.findIndex(attachment => attachment.defaultAttachment)
  if (defaultIndex !== -1) {
    attachments = arrayMoveToIndex(attachments, defaultIndex, 0)
  }
  if (attachments.length > MAX_ATTACHMENTS_COUNT) {
    attachments = attachments.slice(0, MAX_ATTACHMENTS_COUNT)
  }
  return attachments
})

const goToSlide = (clickedIndex: number = 0, swiperInstance: SwiperType) => {
  let goToIndex = window.innerWidth < 768 ? clickedIndex : clickedIndex
  if (goToIndex === displayedImages.value.length) {
    goToIndex = 0
  }
  swiperInstance.slideTo(goToIndex);
}

const updateActiveThumbnail = (eventName: string) => {
  galleryThumbs.el.querySelector('.swiper-slide-active')?.classList.remove('swiper-slide-active')
  if (eventName === 'touchEnd') {
    let thumbIndex = galleryMain.realIndex + 1
    galleryThumbs.el.querySelector(`.swiper-slide:nth-child(${thumbIndex})`)?.classList.add('swiper-slide-active')
  } else {
    galleryThumbs.clickedSlide.classList.add('swiper-slide-active')
  }
}

const initSwiper = () => {
  galleryThumbs = new Swiper("#product-details-images__thumbs", {
    watchSlidesProgress: true,
    allowSlideNext: true,
    allowSlidePrev: true,
    loop: false,
    spaceBetween: 10,
    direction: 'horizontal',
    slidesPerView: 'auto',
    slideActiveClass: 'swiper-slide--active',
    breakpoints: {
      768: {
        loop: false,
        allowSlideNext: false,
        allowSlidePrev: false,
        direction: 'vertical',
        slidesPerView: MAX_ATTACHMENTS_COUNT,
      }
    }
  });

  galleryMain = new Swiper("#product-details-images__main", {
    watchOverflow: true,
    watchSlidesProgress: true,
    preventInteractionOnTransition: true,
    loop: false,
    spaceBetween: 10,
    effect: 'fade',
    centeredSlides: true,
    fadeEffect: {
      crossFade: true
    },
    direction: 'horizontal',
    thumbs: {
      swiper: galleryThumbs
    },
    breakpoints: {
      768: {
        loop: false,
        direction: 'vertical'
      }
    }
  });

  galleryMain.on('touchEnd', () => {
    setTimeout(() => {
      updateActiveThumbnail('touchEnd')
    }, 100);
  });

  galleryThumbs.on('click', () => {
    goToSlide(galleryThumbs.clickedIndex, galleryMain)
    setTimeout(() => {
      updateActiveThumbnail('click')
    }, 100);
  });
}
</script>
