export const DEFAULT_LANGUAGE = 'en'

export const PRODUCT_STORE_PAGE_SIZES = [100]
export const PRODUCT_STORE_FILTERS = {
  SORT: {
    BEST_SELLING: 'bestseller-desc',
    ALPHABETICAL_ASC: 'name-asc',
    ALPHABETICAL_DES: 'name-desc',
    PRICE_ASC: 'price-asc',
    PRICE_DES: 'price-desc',
  },
  PRODUCT_TYPE: {
    DEVICE: 'device',
    ACCESSORY: 'accessory',
    LICENSE: 'license',
  },
  STOCK: {
    IN_STOCK: 'in_stock',
    LIMITED_STOCK: 'low_stock',
    OUT_OF_STOCK: 'out_of_stock',
  },
}

export const PRODUCT_REMARK_VALUES = {
  OUT_OF_STOCK: 'OUT-OF-STOCK',
}

export const FORM_PAGES_IDS = {
  SHIPPING: 'ShippingInformation',
  PAYMENT: 'PaymentInformation',
  REVIEW: 'ReviewInformation',
}

export const FORM_FIELD_PATH = {
  EMAIL_ADDRESS: 'ShippingInformation_ShippingGroup_EmailAddress',
  SHIPPING_PROVINCE: 'ShippingInformation_ShippingGroup_Province',
  BILLING_PROVINCE: 'PaymentInformation_BillingGroup_BillingAddress_Province',
  BILLING_SAME_AS_SHIPPING: 'PaymentInformation_BillingGroup_BillingAddressSameAsShipping',
  CREDIT_CARD_NUMBER: 'PaymentInformation_PaymentGroup_ShopPayment_CardNumber',
}

export const DTS_CONSUMER_ID = 'BBSSC'

export const STOCK_STATUSES = {
  IN_STOCK: 'inStock',
  LIMITED_STOCK: 'limitedStock',
  OUT_OF_STOCK: 'outOfStock',
}

export const PRODUCT_TAB_NAMES = {
  DESCRIPTION: 'description',
  SPECS: 'specifications',
}

export const DEFAULT_STORE_VALUES = {
  PRODUCT: {
    PRODUCT: undefined,
    PRODUCTS: [],
    OFFSET: 0,
    PAGE_SIZE: PRODUCT_STORE_PAGE_SIZES[0],
    CURRENT_PAGE: 1,
    SEARCHED_PRODUCT_NAME: '',
    FILTER_SORT_BY: '',
    FILTER_DEVICE_TYPE: [],
    FILTER_STOCK: [],
  },
  CHECKOUT: {
    DISCOUNTS: [],
    ITEMS: [],
    TAXES: {},
    SUBTOTAL: 0,
    TOTAL: 0,
  },
}
