<template>
  <el-image
    class="product-image"
    :src="imageUrl"
    :alt="productName"
    fit="contain">
    <template #placeholder>
      <div class="product-image__loading-placeholder" />
    </template>
    <template #error>
      <div class="image-slot">
        <el-icon>
          <icon-picture />
        </el-icon>
      </div>
    </template>
  </el-image>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { Picture as IconPicture } from '@element-plus/icons-vue'
import { Attachment } from '@/domain/attachment'
import { getAttachmentUrl, getDefaultAttachmentIndex } from '@/utils/attachment'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

type ProductImage = {
  attachments: Attachment[],
  imageUrl?: string,
  productName?: string,
}

const props = withDefaults(defineProps<ProductImage>(), {
  imageUrl: '',
  productName: ''
})

const imageUrl = computed(() => {
  if (props.imageUrl && props.imageUrl !== '') {
    return getAttachmentUrl(props.imageUrl)
  }
  if (!props.attachments || !props.attachments.length) return ''
  const defaultIndex = getDefaultAttachmentIndex(props.attachments)
  return getAttachmentUrl(props.attachments[defaultIndex].url)
})

const productName = computed(() => props.productName !== '' ? props.productName : t('components.product-image.default-alt'))
</script>
