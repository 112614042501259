<template>
  <div class="product-catalog-filters">
<!--    <el-dropdown
      @command="onPageSizeChange"
      placement="bottom-end"
      trigger="click"
      class="product-catalog-filters__display-btn hidden-xs-only">
      <el-button text>
        {{ $t('components.product-catalog-filters.display') }}:&nbsp;
        <strong>{{ productStore.pageSize }} {{ $t('components.product-catalog-filters.per-page') }}</strong>
        <el-icon class="el-icon--right"><arrow-down /></el-icon>
      </el-button>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item v-for="pageSize in PRODUCT_STORE_PAGE_SIZES" :command="pageSize">
            {{ pageSize }} {{ $t('components.product-catalog-filters.per-page') }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>-->

    <el-popover
      placement="bottom-end"
      :width="230"
      :show-arrow="false"
      trigger="click"
      popper-class="product-catalog-filters__filter-list-wrapper">
      <template #reference>
        <template v-if="hasSelectedFilters">
          <el-button text class="product-catalog-filters__filter-btn">
            <el-badge :value="productStore.selectedFiltersLength" class="item"></el-badge>
            {{ $t('components.product-catalog-filters.filter') }}
          </el-button>
        </template>
        <template v-else>
          <el-button text :icon="Filter" class="product-catalog-filters__filter-btn">{{ $t('components.product-catalog-filters.filter') }}</el-button>
        </template>
      </template>
      <div class="product-catalog-filters__filter-list">
        <el-button plain
                   @click="onClearFilters"
                   :disabled="productStore.selectedFiltersLength === 0"
                   class="product-catalog-filters__clear-btn">
          {{ $t('button.clear-all') }}
        </el-button>
        <el-collapse v-model="activeTab" accordion>
          <el-collapse-item :title="$t('components.product-catalog-filters.filters.sort.title')" name="1">
            <el-radio-group v-model="productStore.filterSortBy" @change="onSortFilterChange">
              <el-radio :label="FILTERS.SORT.ALPHABETICAL_ASC">{{ $t('components.product-catalog-filters.filters.sort.alpha-asc') }}</el-radio>
              <el-radio :label="FILTERS.SORT.ALPHABETICAL_DES">{{ $t('components.product-catalog-filters.filters.sort.alpha-desc') }}</el-radio>
              <el-radio :label="FILTERS.SORT.PRICE_ASC">{{ $t('components.product-catalog-filters.filters.sort.price-asc') }}</el-radio>
              <el-radio :label="FILTERS.SORT.PRICE_DES">{{ $t('components.product-catalog-filters.filters.sort.price-desc') }}</el-radio>
            </el-radio-group>
          </el-collapse-item>
          <el-collapse-item :title="$t('components.product-catalog-filters.filters.product-type.title')" name="2">
            <el-button plain
                       @click="onClearDeviceFilters"
                       :disabled="productStore.filterDeviceType.length === 0"
                       class="product-catalog-filters__clear-btn">
              {{ $t('button.clear') }}
            </el-button>
            <el-checkbox-group v-model="productStore.filterDeviceType" @change="onDeviceTypeFiltersChange">
              <el-checkbox :label="FILTERS.PRODUCT_TYPE.DEVICE">{{ $t('components.product-catalog-filters.filters.product-type.device') }}</el-checkbox>
              <el-checkbox :label="FILTERS.PRODUCT_TYPE.ACCESSORY">{{ $t('components.product-catalog-filters.filters.product-type.accessory') }}</el-checkbox>
<!--              <el-checkbox :label="FILTERS.PRODUCT_TYPE.LICENSE">{{ $t('components.product-catalog-filters.filters.product-type.license') }}</el-checkbox>-->
            </el-checkbox-group>
          </el-collapse-item>
          <el-collapse-item :title="$t('components.product-catalog-filters.filters.stock.title')" name="3">
            <el-button plain
                       @click="onClearStockFilters"
                       :disabled="productStore.filterStock.length === 0"
                       class="product-catalog-filters__clear-btn">
              {{ $t('button.clear') }}
            </el-button>
            <el-checkbox-group v-model="productStore.filterStock" @change="onStockFiltersChange">
              <el-checkbox :label="FILTERS.STOCK.IN_STOCK">{{ $t('components.product-catalog-filters.filters.stock.in-stock') }}</el-checkbox>
              <el-checkbox :label="FILTERS.STOCK.LIMITED_STOCK">{{ $t('components.product-catalog-filters.filters.stock.limited-stock') }}</el-checkbox>
              <el-checkbox :label="FILTERS.STOCK.OUT_OF_STOCK">{{ $t('components.product-catalog-filters.filters.stock.out-of-stock') }}</el-checkbox>
            </el-checkbox-group>
          </el-collapse-item>
        </el-collapse>
      </div>
    </el-popover>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'
import { useProductStore } from '@/store/product-store'
import { PRODUCT_STORE_PAGE_SIZES, PRODUCT_STORE_FILTERS as FILTERS } from '@/utils/constants'

const productStore = useProductStore()

const activeTab = ref('0')

const hasSelectedFilters = computed(() => {
  return productStore.selectedFiltersLength > 0
})

const onPageSizeChange = (newPageSize: number) => {
  productStore.setPageSize(newPageSize)
  clearPageAndFetchProducts()
}

const onSortFilterChange = (newSortFilter: string) => {
  productStore.setFilterSortBy(newSortFilter)
  clearPageAndFetchProducts()
}

const onDeviceTypeFiltersChange = (newDeviceTypeFilters: string[]) => {
  productStore.setFilterDeviceType(newDeviceTypeFilters)
  clearPageAndFetchProducts()
}

const onStockFiltersChange = (newStockFilters: string[]) => {
  productStore.setFilterStock(newStockFilters)
  clearPageAndFetchProducts()
}

const onClearFilters = () => {
  productStore.clearFilters()
  clearPageAndFetchProducts()
}

const onClearDeviceFilters = () => {
  productStore.clearFilterDeviceType()
  clearPageAndFetchProducts()
}

const onClearStockFilters = () => {
  productStore.clearFilterStock()
  clearPageAndFetchProducts()
}

const clearPageAndFetchProducts = () => {
  productStore.clearOffset()
  productStore.clearCurrentPage()
  productStore.fetchProducts()
}

const setInitialActiveTab = () => {
  if (productStore.filterSortBy !== '') {
    activeTab.value = '1'
  } else if (productStore.filterDeviceType.length) {
    activeTab.value = '2'
  } else if (productStore.filterStock.length) {
    activeTab.value = '3'
  }
}

onMounted(() => {
  setInitialActiveTab()
})
</script>
