import { STOCK_STATUSES } from '@/utils/constants'

export const getStockStatus = (inventoryLevel: string) => {
  if (inventoryLevel === 'IN_STOCK') {
    return STOCK_STATUSES.IN_STOCK
  } else if (inventoryLevel === 'LOW_STOCK') {
    return STOCK_STATUSES.LIMITED_STOCK
  } else {
    return STOCK_STATUSES.OUT_OF_STOCK
  }
}
