<template>
  <div class="product-quantity" :class="{ 'product-quantity--is-small': isSmallLayout }">
    <label :for="inputId" class="product-quantity__label" :class="{ 'sr-only': !showLabel }">
      {{ $t('components.product-quantity.title') }}
    </label>
    <el-input-number
      :id="inputId"
      v-model="quantitySelected"
      :min="minQuantity"
      :max="maxQuantity"
      :disabled="!isInStock"
      step-strictly
      value-on-clear="min"
      @change="$emit('change', quantitySelected)"
      @keypress="onKeypress"
    />
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, computed } from 'vue'
import { v4 as uuidv4 } from 'uuid'

type ProductQuantity = {
  quantitySelected: number,
  isInStock?: boolean,
  showLabel: boolean,
  isSmallLayout?: boolean,
}

const props = withDefaults(defineProps<ProductQuantity>(), {
  quantitySelected: 1,
  isInStock: true,
  showLabel: true,
  isSmallLayout: false,
})

const emits = defineEmits(['change'])

const quantitySelected = ref(1)
const minQuantity = ref(1)
const maxQuantity = ref(999)

const inputId = computed(() => `input-quantity-${uuidv4()}`)

// prevents input other than numbers
// documented here : https://www.snagoff.com/blog/prevent-non-numeric-in-input-type-number-textfield-hide-arrow/
const onKeypress = (event) => {
  if (event.keyCode < 48 || event.keyCode > 57) {
    if (event.key === '-' || event.key === 'e' || event.key !== "Backspace") event.preventDefault()
  }
}

onMounted(() => {
  quantitySelected.value = props.quantitySelected!
})
</script>
