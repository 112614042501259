import { GqlProduct } from "@/domain/__generated__/graph-ql-types";
import { Product } from "@/domain/product";
import { graphQlToAtachmentsAdapter } from './attachment-adapter'

const graphQlToAddonAdapter = (data: GqlProduct): Product => {
  return {
    id: data.id,
    family: data.family?.toString(),
    name: data.name?.toString(),
    price: data.price?.toString(),
    pricebookEntryId: data.pricebookEntryId?.toString(),
    attachments: graphQlToAtachmentsAdapter(data.attachments ?? []),
  }
}

export {
  graphQlToAddonAdapter
}
