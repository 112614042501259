<template>
  <component v-if="isSupportedTag && typeof element.content === 'object'"
             v-bind="(element.attrs || {})"
             :is="element.tag || 'div'"
             class="static-html-content"
  >
    <static-html-content v-for="(childElement, idx) in element.content"
                         :key="'static-html-content-' + idx"
                         :element="childElement" />
  </component>
  <component v-else-if="isSupportedTag"
             v-bind="(element.attrs || {})"
             :is="element.tag || 'div'"
             v-html="element.content"
             class="static-html-content"
  />
</template>

<script setup lang="ts">
import { computed } from 'vue'

type StaticHtmlContent = {
  element: any,
}

const props = withDefaults(defineProps<StaticHtmlContent>(), {
  element: {
    tag: 'div',
    content: '',
    attrs: {},
  },
})

const SUPPORTED_TAGS = ['div', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'ol', 'ul', 'li', 'strong']

const isSupportedTag = computed(() => {
  return SUPPORTED_TAGS.includes(props.element.tag)
})
</script>
