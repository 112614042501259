export const getCreditCardType = (cardNumber: string) => {
  let amex = new RegExp('^3[47][0-9]{13}$')
  let visa = new RegExp('^4[0-9]{12}(?:[0-9]{3})?$')
  let mastercard = new RegExp('^5[1-5][0-9]{14}$')
  let mastercard2 = new RegExp('^2[2-7][0-9]{14}$')

  if (visa.test(cardNumber)) {
    return 'VISA'
  }
  if (amex.test(cardNumber)) {
    return 'AMEX'
  }
  if (mastercard.test(cardNumber) || mastercard2.test(cardNumber)) {
    return 'MASTERCARD'
  }
  return undefined
}
