import { useMutation } from '@vue/apollo-composable'
import { GqlCreateOrder, GqlOrder, GqlPriceValidation, GqlValidPrice } from '@/src/domain/__generated__/graph-ql-types'
import { SUBMIT_ORDER, VALIDATE_PRICE_CART, VALIDATE_PRICE_CHECKOUT } from '@/api/graph-ql/queries/order-queries'
import { getLocaleName } from '@/utils/i18n'

export const submitOrder = (gqlCreateOrder: GqlCreateOrder): GqlOrder|any => {
  const { mutate: createOrder, onDone, onError } = useMutation<GqlOrder>(SUBMIT_ORDER, () => ({ variables: { createOrder: gqlCreateOrder, lang: getLocaleName(), } }))
  const promise = createOrder()
    .then((result: any) => result)
    .catch((error: any) => console.log('error submitting order', error))
  return { promise, onDone, onError }
}

export const validatePriceCart = (gqlPriceValidation: GqlPriceValidation): GqlValidPrice|any => {
  const { mutate: validatePriceCart, onDone, onError } = useMutation<GqlValidPrice>(VALIDATE_PRICE_CART, () => ({ variables: { priceValidation: gqlPriceValidation } }))
  const promise = validatePriceCart()
    .then((result: any) => result)
    .catch((error: any) => console.log('error validating cart prices', error))
  return { promise, onDone, onError }
}

export const validatePriceCheckout = (gqlPriceValidation: GqlPriceValidation): GqlValidPrice|any => {
  const { mutate: validatePriceCheckout, onDone, onError } = useMutation<GqlValidPrice>(VALIDATE_PRICE_CHECKOUT, () => ({ variables: { priceValidation: gqlPriceValidation } }))
  const promise = validatePriceCheckout()
    .then((result: any) => result)
    .catch((error: any) => console.log('error validating checkout prices', error))
  return { promise, onDone, onError }
}
