export const isElementVisible = (elementId: string) => {
  const rect = document.getElementById(elementId)?.getBoundingClientRect();
  const elemTop = rect?.top ?? 0;
  const elemBottom = rect?.bottom ?? 0;
  return (elemTop >= 0) && (elemBottom <= window.innerHeight);
}

export const blurActiveElement = () => {
  if (document.activeElement instanceof HTMLElement) {
    document.activeElement?.blur()
  }
}
