export const deepCopyObject = (input, ignored) => {
  // Return the value if input is not an object
  if (input == null || typeof input !== 'object')
    return input;
  // Create an array or object to hold the values
  const output = Array.isArray(input) ? [] : {};
  for (const key of Object.keys(input)) {
    if (ignored === null || ignored === void 0 ? void 0 : ignored.includes(key))
      continue;
    // Recursively (deep) copy for nested objects, including arrays
    output[key] = deepCopyObject(input[key], ignored);
  }
  return output;
};
