import gql from 'graphql-tag'

export const GET_PRODUCTS = gql`
  query GET_PRODUCTS($catalogCode: String!, $lang: String!, $offset: Int, $pagesize: Int, $orderBy: String, $typeFilter: [String], $stockFilter: [String]) {
    products(
      catalogCode: $catalogCode
      lang: $lang
      offset: $offset
      pagesize: $pagesize
      orderBy: $orderBy
      typeFilter: $typeFilter
      stockFilter: $stockFilter
    ) {
      pageInfo {
        totalCount
      }
      starterKitId
      productList {
        id
        name
        price
        inventoryLevel
        family
        attachments {
          url
          contentType
          sequenceNumber
          defaultAttachment
        }
      }
    }
  }
`

export const GET_PRODUCT = gql`
  query GET_PRODUCT ($catalogCode: String!, $offerCode: String!, $lang: String!) {
    product (catalogCode: $catalogCode, offerCode: $offerCode, lang: $lang) {
      id
      pricebookEntryId
      name
      price
      description
      seoDescription
      family
      inventoryLevel
      productAttributes {
        code
        label
        value
        showInMarketplace
      }
      attachments {
        title
        url
        contentType
        sequenceNumber
        defaultAttachment
      }
      suggestionList {
        id
        pricebookEntryId
        name
        price
        family
        inventoryLevel
        attachments {
          url
          contentType
          sequenceNumber
          defaultAttachment
        }
      }
      recommendedAddons {
        id
        pricebookEntryId
        name
        price
        family
        inventoryLevel
        attachments {
          url
          contentType
          sequenceNumber
          defaultAttachment
        }
      }
    }
  }
`

export const CREATE_ORDER = gql`
  mutation createOrder ($createOrder : CreateOrder, $lang : String) {
    createOrder(createOrder : $createOrder, lang : $lang)
  }
`

export const GET_ORDER = gql`
  query GET_ORDER ($salesforceId: ID, $emailAddress: String) {
    order(salesforceId: $salesforceId, emailAddress: $emailAddress) {
      salesforceId
      orderNumber
      orderStatus
      totalAmount
      companyName
      orderName
      comment
      createdDate
      emailAddress
      itemList {
        quantity
        unitPrice
        productName
      }
      billingAddress {
        billingStreet
        billingCity
        billingState
        billingPostalCode
        billingCountry
      }
      shippingAddress {
        shippingStreet
        shippingCity
        shippingState
        shippingPostalCode
        shippingCountry
      }
    }
  }
`
