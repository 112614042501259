<template>
  <checkout-session-tracker v-if="!isLoading" />
  <checkout-header />
  <div class= 'checkout-steps-background' :class="hasSubmitted ? 'checkout-steps-background--hidden' :''"/>
  <el-main v-loading="showLoadingAnimation" class="checkout" id="main-content" role="main" :aria-label="$t('accessibility.landmarks.main')">
    <div class="checkout__neoform-wrapper" :style="{ 'min-height': checkoutSummaryHeight }" role="form" :aria-label="$t('accessibility.landmarks.checkout-form')">
      <input v-model="dtsTempCcValue" type="hidden" id="dtsCcInput" />
      <rky-wc-neo-form-lib
        v-if="!isLoadingFormSchema && !isOnError"
        :action="formAction"
        :formDefinition.prop="formDefinition"
        :formExistingData.prop="formExistingData"
        :is-draft.prop="false"
        :bypass-form-validation.prop="false"
        :is-edit-mode.prop="false"
        :is-clone-mode.prop="false"
        :is-read-only.prop="false"
        :options.prop="NEOFORM_OPTIONS"
        :draft-data.prop="draftData"
        :template-data.prop="templateData"
        :submission-state="submissionState"
        :user-permissions.prop="userPermissions"
        :locale="locale"
        @wcMounted="onWcMounted"
        @pageChanged="onPageChanged"
        @backToHomePage="navigateToCart"
        @back="navigateToCart"
        @submit="onSubmitForm"
      >
        <checkout-confirmation-message
          slot="confirmation-page"
          v-if="hasSubmitted"
          :form-data="formDefinition"
          :submission-state="submissionState"
          :submission-failed="submissionFailed"
          :options="NEOFORM_OPTIONS"
          @redirectToHome="redirectToHome"
          @returnToOffering="onReturnToOffering"
        />
      </rky-wc-neo-form-lib>
    </div>
    <checkout-summary v-if="!isLoading && !hasSubmitted && !isOnError" @checkout-summary-mounted="setCheckoutSummaryHeight" />
  </el-main>
</template>

<script setup lang="ts">
import {ref, onBeforeMount, onBeforeUnmount, computed, watch, onMounted, reactive} from 'vue'
import { SUBMISSION_STATES } from 'rky-neo-form-lib-utils'
import { useHead } from '@vueuse/head'
import { useCheckoutStore } from '@/store/checkout-store'
import { useCartStore } from '@/store/cart-store'
import { navigateToCart, navigateToHome } from '@/router'
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import FormApi from '@/api/form-api'
import ShopApi from '@/api/shop-api'
import { submitOrder } from '@/api/graph-ql/order-api'
import { FORM_PAGES_IDS, FORM_FIELD_PATH, DTS_CONSUMER_ID } from '@/utils/constants'
import { getTranslatedTextByKey } from '@/utils/i18n'
import {
  NEOFORM_OPTIONS,
  EMPTY_FORM_DEFINITION,
  fetchDtsScripts,
  getCheckoutSummaryHeight,
  getInitialOrder,
  getOrderWithCcToken
} from '@/utils/checkout'
import { onWindowResizeEvent } from '@/composables/use-resize'
import CheckoutSessionTracker from '@/components/CheckoutSessionTracker.vue'
import CheckoutHeader from '@/components/CheckoutHeader.vue'
import CheckoutSummary from '@/components/CheckoutSummary.vue'
import CheckoutConfirmationMessage from '@/components/CheckoutConfirmationMessage.vue'

const checkoutStore = useCheckoutStore()
const cartStore = useCartStore()
const { t, locale } = useI18n({ useScope: 'global' })

type InputChangeEvent = {
  path: string,
  value: string|boolean,
  valid: boolean,
}

const formExistingData: any = null
const draftData: any = {}
const formAction = 'add'
const templateData: any = {}
const userPermissions: string[] = []
const formDefinition: any = ref(EMPTY_FORM_DEFINITION)
const isLoadingFormSchema = ref(true)
const isLoadingNeoformWC = ref(true)
const isOnError = ref(false)
const submissionState = ref(SUBMISSION_STATES.NOT_SUBMITTED)
const checkoutSummaryHeight = ref('0')
const dtsPassKey = ref('')
const dtsConsumerID = ref(DTS_CONSUMER_ID)
const dtsTempCcValue = ref('')
const formFields = ref({
  EMAIL_ADDRESS: '',
  SHIPPING_PROVINCE: '',
  BILLING_PROVINCE: '',
  BILLING_SAME_AS_SHIPPING: true,
})
const createOrder = ref({})

const metaConfig = { // expose to unit tests
  title: getTranslatedTextByKey('seo.pages.checkout.title'),
  description: getTranslatedTextByKey('seo.pages.checkout.description'),
}

useHead({
  title: metaConfig.title,
  meta: [{
    name: 'description',
    content: metaConfig.description,
  }],
})

const isLoading = computed(() => {
  return isLoadingFormSchema.value || isLoadingNeoformWC.value || isSubmitting.value || checkoutStore.loadingValidatePrice
})

const showLoadingAnimation = computed(() => {
  return isLoadingFormSchema.value || isLoadingNeoformWC.value || checkoutStore.loadingValidatePrice
})

const submissionFailed = computed(() => {
  return submissionState.value === SUBMISSION_STATES.SUBMIT_FAIL
})

const isSubmitting = computed(() => {
  return submissionState.value === SUBMISSION_STATES.SUBMITTING
})

const hasSubmitted = computed(() => {
  return [
    SUBMISSION_STATES.SUBMIT_SUCCESS,
    SUBMISSION_STATES.SUBMIT_SUCCESS_NO_ATTACHMENT,
    SUBMISSION_STATES.SUBMIT_FAIL
  ].includes(submissionState.value)
})

const getFormSchema = async (): Promise<void> => {
  const response = await FormApi.getFormSchema('checkout')
  formDefinition.value = response.data
}

const fetchDtsPassKey = async (): Promise<void> => {
  const response = await ShopApi.getPassKey()
  dtsPassKey.value = response.data
}

const generateInitialOrder = (payload: any) => {
  createOrder.value = getInitialOrder(payload, dtsTempCcValue.value, checkoutStore.items)
}

const addCcTokenToOrder = (token: string = '') => {
  createOrder.value = getOrderWithCcToken(createOrder.value, token)
}

const dtsSuccessHandler = (response) => {
  // extract the response parameters:response.statusCode,  response.token, response.dtsRefId, response.cardtype(optional), response.Isocntryname (optional),
  // store the token and dtsRefId and submit the BSS HTML form
  // process the response parameters as appropriate
  console.log('=== DTS === dtsSuccessHandler', response)
  addCcTokenToOrder(response.token)
  const { onDone, onError } = submitOrder(createOrder.value)
  onDone(() => onSubmitFormSuccess())
  onError(() => onSubmitFormFail())
}

const dtsErrorHandler = (errorCode: string, dtsRefId: string) => {
  // handle the error based on the received error code
  console.log('=== DTS === dtsErrorHandler - errorCode', errorCode)
  console.log('=== DTS === dtsErrorHandler - dtsRefId', dtsRefId)
  onSubmitFormFail()
}

const dtsVerifyInfo = () => {
  let dtsTokenizationPlugin = new DTSTokenizationPlugin()
  dtsTokenizationPlugin.setConsumerID(dtsConsumerID.value)
  dtsTokenizationPlugin.setApplicationID(dtsConsumerID.value)
  dtsTokenizationPlugin.setPassKey(dtsPassKey.value)
  dtsTokenizationPlugin.setPanElementID('dtsCcInput')
  dtsTokenizationPlugin.setTimeout(10000)
  dtsTokenizationPlugin.setSuccessHandler(dtsSuccessHandler)
  dtsTokenizationPlugin.setErrorHandler(dtsErrorHandler)
  dtsTokenizationPlugin.tokenize()
}

const onSubmitFormSuccess = () => {
  submissionState.value = SUBMISSION_STATES.SUBMIT_SUCCESS
  dtsTempCcValue.value = ''
  createOrder.value = {}
  checkoutStore.clearItems()
  cartStore.clearItems()
}

const onSubmitFormFail = () => {
  submissionState.value = SUBMISSION_STATES.SUBMIT_FAIL
  dtsTempCcValue.value = ''
  createOrder.value = {}
}

const onSubmitForm = async (event: CustomEvent) => {
  submissionState.value = SUBMISSION_STATES.SUBMITTING
  const formPayload = event.detail[0]
  const ccValue = formPayload.result[FORM_FIELD_PATH.CREDIT_CARD_NUMBER].value ?? ''
  const areDtsScriptsLoaded = typeof DTSTokenizationPlugin === 'function'
  dtsTempCcValue.value = ccValue.replace(/[^0-9]/g, '')

  generateInitialOrder(formPayload)

  if (!areDtsScriptsLoaded) await fetchDtsScripts()
  await fetchDtsPassKey()

  if (dtsPassKey.value === '' || ccValue === '') {
    onSubmitFormFail()
    return
  }

  // wait for DTS's 'generateKeyPairForJS' call to finish
  setTimeout(() => {
    dtsVerifyInfo()
  }, areDtsScriptsLoaded ? 0 : 1000);
}

const onWcMounted = () => {
  isLoadingNeoformWC.value = false
}

const onPageChanged = (event: CustomEvent) => {
  const newPageId = event.detail[1]
  const oldPageId = checkoutStore.formCurrentPageId
  checkoutStore.setFormCurrentPageId(newPageId ?? FORM_PAGES_IDS.SHIPPING)

  if ((newPageId === FORM_PAGES_IDS.PAYMENT && oldPageId === FORM_PAGES_IDS.SHIPPING) ||
      (newPageId === FORM_PAGES_IDS.REVIEW && oldPageId === FORM_PAGES_IDS.SHIPPING)) {
    doValidatePrice()
  }
}

const doValidatePrice = async() => {
  const provinceCode = formFields.value.BILLING_SAME_AS_SHIPPING ? formFields.value.SHIPPING_PROVINCE : formFields.value.BILLING_PROVINCE
  checkoutStore.setEmailAddress(formFields.value.EMAIL_ADDRESS)
  checkoutStore.setBillingProvinceCode(provinceCode)
  try {
    await checkoutStore.validatePrice()
    if (!checkoutStore.hasItems) {
      redirectToHome()
      showCartEmptiedMessage()
    }
  } catch (error) {
    onSubmitFormFail()
  }
}

const redirectToHome = () => {
  navigateToHome()
}

const showCartEmptiedMessage = () => {
  ElMessage({
    type: 'error',
    duration: 0,
    zIndex: 3000, // on top of dialogs,
    showClose: true,
    message: t('notification.error-cart-emptied.message').toString(),
  })
}

const onReturnToOffering = () => {
  submissionState.value = SUBMISSION_STATES.NOT_SUBMITTED
  document.dispatchEvent(new CustomEvent('returnToOffering', { detail: { activePageIndex: 1 } }))
}

const saveFormFields = (eventDetail: InputChangeEvent) => {
  if (eventDetail.valid && eventDetail.path === FORM_FIELD_PATH.EMAIL_ADDRESS) {
    formFields.value.EMAIL_ADDRESS = eventDetail.value.toString()
  }

  if (eventDetail.valid && eventDetail.path === FORM_FIELD_PATH.SHIPPING_PROVINCE) {
    formFields.value.SHIPPING_PROVINCE = eventDetail.value.toString()
  }

  if (eventDetail.valid && eventDetail.path === FORM_FIELD_PATH.BILLING_PROVINCE) {
    formFields.value.BILLING_PROVINCE = eventDetail.value.toString()
  }

  if (eventDetail.valid && eventDetail.path === FORM_FIELD_PATH.BILLING_SAME_AS_SHIPPING) {
    formFields.value.BILLING_SAME_AS_SHIPPING = !!eventDetail.value
  }
}
const onInputValueChange = (event: any): void => {
  const eventDetail = event.detail
  if (eventDetail) {
    saveFormFields(eventDetail)
  }
}

const setCheckoutSummaryHeight = () => {
  checkoutSummaryHeight.value = getCheckoutSummaryHeight()
}

onBeforeMount(async () => {
  checkoutStore.setInitialStoreFromCart()
  checkoutStore.setFormCurrentPageId(FORM_PAGES_IDS.SHIPPING)
  window.addEventListener('valueChanged', onInputValueChange)
  await getFormSchema()
  isLoadingFormSchema.value = false
})

onMounted(() => {
  fetchDtsScripts()
})

onBeforeUnmount(() => {
  window.removeEventListener('valueChanged', onInputValueChange)
})

onWindowResizeEvent(() => {
  setCheckoutSummaryHeight()
})

watch(isLoading, (newValue, oldValue) => {
  if (oldValue && !newValue) {
    setCheckoutSummaryHeight()
  }
})
</script>
