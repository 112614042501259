<template>
  <el-tabs v-model="activeTabId">
    <el-tab-pane :label="$t('components.product-details.tabs.description')" :name="PRODUCT_TAB_NAMES.DESCRIPTION">
        <p><span v-html="product?.description?.replaceAll('\n', '<br>').replaceAll('\t','&emsp;')"></span></p>
    </el-tab-pane>
    <el-tab-pane :label="$t('components.product-details.tabs.specifications')" :name="PRODUCT_TAB_NAMES.SPECS">
      <product-attributes :attributes="product?.productAttributes" :attachments="product?.attachments" />
    </el-tab-pane>
  </el-tabs>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useProductStore } from '@/store/product-store'
import ProductAttributes from '@/components/ProductAttributes.vue'
import { PRODUCT_TAB_NAMES } from '@/utils/constants'

type ProductDetailsTabs = {
  activeTab: string,
}

const props = withDefaults(defineProps<ProductDetailsTabs>(), {
  activeTab: PRODUCT_TAB_NAMES.DESCRIPTION,
})

const productStore = useProductStore()

const activeTabId = computed(() => props.activeTab ?? '')

const product = computed(() => {
  return productStore.product
})
</script>
