<template>
  <shop-header></shop-header>
  <el-main class="terms-conditions static-page" id="main-content" role="main" :aria-label="$t('accessibility.landmarks.main')">
    <h1 class="static-page__title">{{ $t('pages.terms.title') }}</h1>
    <div class="static-page__content">
      <p class="static-page__section-text"><strong>{{ $t('dialog.terms-and-conditions.title') }}</strong></p>
      <p class="static-page__section-text"><strong>{{ $t('dialog.terms-and-conditions.subtitle') }}</strong></p>
      <static-html-content v-for="(element, idx) in staticHtmlElements"
                           :key="'element-' + idx"
                           :element="element" />
    </div>
  </el-main>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useHead } from '@vueuse/head'
import { getTranslatedTextByKey } from '@/utils/i18n'
import ShopHeader from '@/components/ShopHeader.vue'
import { useI18n } from 'vue-i18n'
import StaticHtmlContent from '@/components/StaticHtmlContent.vue'

const { tm } = useI18n()

const metaConfig = { // expose to unit tests
  title: getTranslatedTextByKey('seo.pages.terms-conditions.title'),
  description: getTranslatedTextByKey('seo.pages.terms-conditions.description'),
}

useHead({
  title: metaConfig.title,
  meta: [{
    name: 'description',
    content: metaConfig.description,
  }],
})

const staticHtmlElements = computed(() => {
  return tm('dialog.terms-and-conditions.content') ?? [];
})
</script>
