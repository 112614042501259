<template>
  <div class="dialog-addon-recommendation-item" :class="{ 'dialog-addon-recommendation-item--is-selected': isSelected }">
    <div class="dialog-addon-recommendation-item__checkbox">
      <el-checkbox v-model="isSelected" size="large" @change="updateSelectedRecommendations">
        <span class="sr-only">{{ $t('button.add-to-cart') }}</span>
      </el-checkbox>
    </div>
    <div class="dialog-addon-recommendation-item__wrapper">
      <div class="dialog-addon-recommendation-item__item">
        <div class="dialog-addon-recommendation-item__item__top">
          <product-tag v-if="false" type="" class="dialog-addon-recommendation-item__item__tag">
            {{ $t('components.product-catalog-item.promotion') }}
          </product-tag>
          <product-image :attachments="attachments" :product-name="name" class="dialog-addon-recommendation-item__item__image" />
          <product-category :product-family="family" :is-clickable="false" />
          <h3 class="product-catalog-item__title">{{ name }}</h3>
        </div>
        <div class="dialog-addon-recommendation-item__item__bottom">
          <product-price
            :price="price.toString()"
            :is-on-sale="false"
            :is-small-layout="true">
          </product-price>
          <product-quantity
            :quantity-selected="quantitySelected"
            :show-label="false"
            @change="quantityUpdated">
          </product-quantity>
          <el-link
            type="primary"
            :underline="false"
            @click="onGoToItem(id)"
            class="dialog-addon-recommendation-item__item__cta">
            {{ $t('button.learn-more') }}
          </el-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { navigateToProductDetails } from '@/router'
import ProductImage from '@/components/ProductImage.vue'
import ProductTag from '@/components/ProductTag.vue'
import ProductCategory from '@/components/ProductCategory.vue'
import ProductPrice from '@/components/ProductPrice.vue'
import ProductQuantity from '@/components/ProductQuantity.vue'
import { Attachment } from '@/domain/attachment'

// Copied from : '@/src/domain/product-item'
// Issue : https://github.com/vuejs/core/issues/4294
type ProductItem = {
  id?: string,
  name?: string,
  description?: string,
  family?: string,
  attachments?: Array<Attachment>,
  price?: string,
  pricebookEntryId?: string
}

const props = defineProps<ProductItem>()
const emits = defineEmits(['recommendation-added', 'recommendation-removed', 'dialog-closed'])

const quantitySelected = ref(1)
const isSelected = ref(false)

const updateSelectedRecommendations = () => {
  emits(isSelected.value ? 'recommendation-added' : 'recommendation-removed', props.id, quantitySelected.value)
}

const quantityUpdated = (newQuantity: number) => {
  quantitySelected.value = newQuantity
  if (isSelected.value) {
    emits('recommendation-added', props.id, quantitySelected.value)
  }
}

const onGoToItem = (id: string) => {
  emits('dialog-closed')
  navigateToProductDetails(id)
}
</script>
