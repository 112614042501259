<template>
  <el-dialog v-model="shouldShowDialog"
             class="dialog-addon-recommendations"
             width="90%"
             :title="$t('dialog.addon-recommendations.title')"
             @opened="onDialogOpened"
             @closed="onDialogClosed">
    <div class="dialog-addon-recommendations__top">
      <h3>{{ $t('dialog.addon-recommendations.title') }}</h3>
      <p>{{ $t('dialog.addon-recommendations.subtitle') }}</p>
    </div>
    <div class="dialog-addon-recommendations__form" id="dialogAddonRecommendations">
      <div class="swiper-container" id="addonsSwiper">
        <div class="swiper-wrapper">
          <dialog-addon-recommendation-item v-for="recommendation in recommendations"
                                            v-bind="recommendation"
                                            class="swiper-slide"
                                            @recommendation-added="recommendationAdded"
                                            @recommendation-removed="recommendationRemoved"
                                            @dialog-closed="$emit('dialog-closed')" />
        </div>
      </div>
      <div class="dialog-addon-recommendations__footer">
        <el-button type="primary" @click="submitAddonRecommendations">{{ $t('button.continue') }}</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useProductStore } from '@/store/product-store'
import Swiper from 'swiper'
import { Swiper as SwiperType, SwiperOptions } from 'swiper/types'
import { onWindowResizeEvent } from '@/composables/use-resize'
import DialogAddonRecommendationItem from "@/components/Dialog/DialogAddonRecommendations/DialogAddonRecommendationItem.vue";

type DialogAddonRecommendations = {
  showDialog: boolean,
  isBuyNow: boolean
}

const props = withDefaults(defineProps<DialogAddonRecommendations>(), {
  showDialog: false,
  isBuyNow: false
})

const emits = defineEmits(['add-addons-to-cart', 'go-to-cart', 'dialog-closed', 'dialog-continue', 'dialog-continue-to-cart'])
const { t } = useI18n()
const productStore = useProductStore()

const itemsSelected = ref([])
let addonsSwiper: SwiperType
const isSwiperInitiated = ref(false)
const SWIPER_CONFIG: SwiperOptions = {
  noSwiping: true,
  noSwipingClass: 'product-quantity',
  allowSlideNext: true,
  allowSlidePrev: true,
  loop: false,
  spaceBetween: 15,
  direction: 'horizontal',
  slidesPerView: 1,
  breakpoints: {
    320: {
      slidesPerView: 1,
      centeredSlides: true,
    },
    768: {
      slidesPerView: 2,
      centeredSlides: false,
    },
  }
}

const shouldShowDialog = computed(() => props.showDialog ?? false)

const recommendations = computed(() => {
  const recommendations = productStore.product?.recommendedAddons ?? []
  if (recommendations?.length > 3) {
    recommendations.length = 3
  }
  return recommendations
})

const isMobileOrTablet = computed(() => window.matchMedia('(min-width: 0px) and (max-width: 1199px)'))

const submitAddonRecommendations = () => {
  if (itemsSelected.value.length) {
    if(props.isBuyNow) {
      emits('dialog-continue-to-cart', true, itemsSelected.value);
    } else {
      emits('add-addons-to-cart', false, itemsSelected.value);
    }
  } else {
    emits('dialog-continue');
  }
}

const getRecommendationMatch = (id: string) => {
  return recommendations.value.find(recommendation => recommendation.id === id)
}

const recommendationAdded = (id: string, quantitySelected: number) => {
  const recommendationMatch = getRecommendationMatch(id);
  if (recommendationMatch) {
    recommendationRemoved(id)
    itemsSelected.value.push({
      recommendation: { ...recommendationMatch },
      quantitySelected,
    })
  }
}

const recommendationRemoved = (id: string) => {
  const recommendationMatch = getRecommendationMatch(id);
  if (recommendationMatch) {
    itemsSelected.value = itemsSelected.value.filter(( item ) => {
      return item.recommendation.id !== id;
    })
  }
}

const initOrDestroySwiper = () => {
  if (isMobileOrTablet.value.matches && !isSwiperInitiated.value) {
    initSwiper()
  } else if (!isMobileOrTablet.value.matches && isSwiperInitiated.value) {
    destroySwiper()
  }
}

const initSwiper = () => {
  addonsSwiper = new Swiper("#addonsSwiper", SWIPER_CONFIG)
  isSwiperInitiated.value = true
}

const destroySwiper = () => {
  addonsSwiper.destroy()
  isSwiperInitiated.value = false
}

const onDialogOpened = () => {
  initOrDestroySwiper()
}

const onDialogClosed = () => {
  isSwiperInitiated.value = false
  emits('dialog-closed')
}

onWindowResizeEvent(() => {
  initOrDestroySwiper()
})
</script>
