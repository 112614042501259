import { Product } from '@/domain/product'
import { GqlProduct } from '@/domain/__generated__/graph-ql-types'
import { graphQlToAddonAdapter } from './addon-adapter'
import { graphQlToAtachmentsAdapter } from './attachment-adapter'
import { graphQlToProductAttributeAdapter } from './product-attribute-adapter'

const graphQlToProductAdapter = (data: GqlProduct): Product => {
  return {
    id: data.id,
    name: data.name?.toString() ?? '',
    description: data.description?.toString() ?? '',
    seoDescription: data.seoDescription?.toString() ?? '',
    family: data.family?.toString() ?? '',
    price: data.price?.toString() ?? '',
    inventoryLevel: data.inventoryLevel?.toString(),
    pricebookEntryId: data.pricebookEntryId?.toString(),
    attachments: graphQlToAtachmentsAdapter(data.attachments ?? []),
    productAttributes: data.productAttributes?.map(attribute => graphQlToProductAttributeAdapter(attribute)) ?? [],
    recommendedAddons: data.recommendedAddons?.map((addon: GqlProduct|any) => graphQlToAddonAdapter(addon)) ?? [],
    suggestionList: data.suggestionList?.map((product: GqlProduct|any) => graphQlToProductAdapter(product)) ?? [],
  }
}

const getGraphQlToProductsAdapter = (gqlProducts: GqlProduct[]): Product[] => {
  let products: Product[] = []
  if (gqlProducts) {
    products = gqlProducts.map((product: GqlProduct) => graphQlToProductAdapter(product))
  }
  return products
}

export {
  graphQlToProductAdapter,
  getGraphQlToProductsAdapter,
}
