import { defineStore } from 'pinia'
import {
  getProductsData,
  getProductData,
  GqlProductResponse,
  GqlProductsResponse, GetProductQueryReturn, GetProductsQueryReturn,
} from '@/api/graph-ql/product-api'
import { useAppStore } from '@/store/app-store'
import { ProductState } from '@/store/types/product-state'
import { graphQlToProductAdapter, getGraphQlToProductsAdapter } from '@/api/adapters/product-adapter'
import { GqlProductAttributes } from '@/domain/__generated__/graph-ql-types'
import { Product } from '@/domain/product'
import { DEFAULT_STORE_VALUES } from '@/utils/constants'

export const useProductStore = defineStore('productStore', {
  state: () => ({
    loadingProducts: false,
    loadingProduct: false,
    hasFetchedInitialProducts: false,
    product: DEFAULT_STORE_VALUES.PRODUCT.PRODUCT,
    products: DEFAULT_STORE_VALUES.PRODUCT.PRODUCTS,
    totalCount: 0,
    offset: DEFAULT_STORE_VALUES.PRODUCT.OFFSET,
    pageSize: DEFAULT_STORE_VALUES.PRODUCT.PAGE_SIZE,
    currentPage: DEFAULT_STORE_VALUES.PRODUCT.CURRENT_PAGE,
    searchedProductName: DEFAULT_STORE_VALUES.PRODUCT.SEARCHED_PRODUCT_NAME,
    filterSortBy: DEFAULT_STORE_VALUES.PRODUCT.FILTER_SORT_BY,
    filterDeviceType: DEFAULT_STORE_VALUES.PRODUCT.FILTER_DEVICE_TYPE,
    filterStock: DEFAULT_STORE_VALUES.PRODUCT.FILTER_STOCK,
  } as ProductState),
  actions: {
    async fetchProducts(): Promise<void> {
      this.setLoadingProducts(true)
      this.clearProducts()
      const productsQueryReturn: GetProductsQueryReturn = getProductsData(this.offset, this.pageSize, this.filterSortBy, this.filterDeviceType, this.filterStock)

      return new Promise<void>((resolve, reject) => {
        const setFetchProductsResult = (data: GqlProductsResponse) => {
          const appStore = useAppStore()
          this.setProducts(getGraphQlToProductsAdapter(data.products?.productList ?? []))
          this.setTotalCount(data.products?.pageInfo?.totalCount ?? 0)
          appStore.setStarterKitId(data.products?.starterKitId ?? '')
          const starterKit = data.products?.productList?.filter(p=> p.id === data.products?.starterKitId)
          if (starterKit && starterKit.length >0){
            appStore.setStarterKit(starterKit[0])
          }
          this.setLoadingProducts(false)
          resolve()
        }

        // Handle if result is already loaded from cache
        if (productsQueryReturn.result.value) {
          setFetchProductsResult(productsQueryReturn.result.value)
        }

        // Handle if result is to be fetched from server
        productsQueryReturn.onResult(({ data }) => {
          if (data) setFetchProductsResult(data)
        })

        productsQueryReturn.onError((error: any) => {
          console.log('error fetch products list', error)
          this.setLoadingProducts(false)
          reject(error)
        })
      })
    },
    async fetchProduct(id: string): Promise<void> {
      this.setLoadingProduct(true)
      this.clearProduct()
      const productQueryReturn: GetProductQueryReturn = getProductData(id)

      const setStarterKitId = (productAttributes: GqlProductAttributes[] = []) => {
        const isModem = productAttributes?.some(attr => attr.label === 'IsModem' && attr.value === 'true')
        if (isModem) {
          const appStore = useAppStore()
          const starterKitIdAttribute = productAttributes?.find(attr => attr.label === 'StarterKitId')
          appStore.setStarterKitId(starterKitIdAttribute?.value ?? '')
        }
      }

      return new Promise<void>((resolve, reject) => {
        const setFetchProductResult = (data: GqlProductResponse) => {
          const productResponse = data.product
          this.setProduct(graphQlToProductAdapter(productResponse))
          setStarterKitId(productResponse?.productAttributes ?? [])
          this.setLoadingProduct(false)
          resolve()
        }

        // Handle if result is already loaded from cache
        if (productQueryReturn.result.value) {
          setFetchProductResult(productQueryReturn.result.value)
        }

        // Handle if result is to be fetched from server
        productQueryReturn.onResult(({ data }) => {
          if (data?.product) setFetchProductResult(data)
        })

        productQueryReturn.onError((error: any) => {
          console.log('error fetch product', error)
          this.setLoadingProduct(false)
          reject(error)
        })
      })
    },
    setLoadingProducts(loadingProducts: boolean) {
      this.loadingProducts = loadingProducts
      if (!loadingProducts && !this.hasFetchedInitialProducts) {
        this.hasFetchedInitialProducts = true
      }
    },
    setLoadingProduct(loadingProduct: boolean) {
      this.loadingProduct = loadingProduct
    },
    setProduct(product: Product) {
      this.product = product
    },
    clearProduct() {
      this.product = DEFAULT_STORE_VALUES.PRODUCT.PRODUCT
    },
    setProducts(products: Product[]) {
      this.products = products
    },
    clearProducts() {
      this.products = DEFAULT_STORE_VALUES.PRODUCT.PRODUCTS
    },
    setTotalCount(totalCount: number) {
      this.totalCount = totalCount
    },
    setOffset() {
      // @ts-ignore
      this.offset = (this.currentPage - 1) * this.pageSize ?? 0
    },
    clearOffset() {
      this.offset = DEFAULT_STORE_VALUES.PRODUCT.OFFSET
    },
    setPageSize(pageSize: number) {
      this.pageSize = pageSize
    },
    setCurrentPage(currentPage: number) {
      this.currentPage = currentPage
    },
    clearCurrentPage() {
      this.currentPage = DEFAULT_STORE_VALUES.PRODUCT.CURRENT_PAGE
    },
    setSearchedProductName(searchedProductName: string) {
      this.searchedProductName = searchedProductName
    },
    clearSearchedProductName() {
      this.searchedProductName = DEFAULT_STORE_VALUES.PRODUCT.SEARCHED_PRODUCT_NAME
    },
    setFilterSortBy(filterSortBy: string) {
      this.filterSortBy = filterSortBy
    },
    clearFilterSortBy() {
      this.filterSortBy = DEFAULT_STORE_VALUES.PRODUCT.FILTER_SORT_BY
    },
    setFilterDeviceType(filterDeviceType: string[]) {
      this.filterDeviceType = filterDeviceType
    },
    clearFilterDeviceType() {
      this.filterDeviceType = DEFAULT_STORE_VALUES.PRODUCT.FILTER_DEVICE_TYPE
    },
    setFilterStock(filterStock: string[]) {
      this.filterStock = filterStock
    },
    clearFilterStock() {
      this.filterStock = DEFAULT_STORE_VALUES.PRODUCT.FILTER_STOCK
    },
    clearFilters() {
      this.clearCurrentPage()
      this.clearFilterSortBy()
      this.clearFilterDeviceType()
      this.clearFilterStock()
    },
  },
  getters: {
    totalPages(state) {
      return Math.ceil(state.totalCount / state.pageSize)
    },
    currentPageStartIndex(state) {
      return state.offset + 1
    },
    currentPageEndIndex(state) {
      const endIndex = (state.currentPage * state.pageSize)
      return endIndex > state.totalCount ? state.totalCount : endIndex
    },
    selectedFiltersLength(state) {
      return (state.filterSortBy !== '' ? 1 : 0) +
        state.filterDeviceType.length +
        state.filterStock.length
    },
  },
})
