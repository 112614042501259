<template>
  <dialog-session-timed-out v-if="sessionTimeoutMessageDialogVisible"
                            :show-dialog="sessionTimeoutMessageDialogVisible"
                            @dialog-closed="onDialogSessionTimeoutClosed" />
  <dialog-invalid-cart v-if="cartStore.showInvalidItemsMessage"
                       :show-dialog="cartStore.showInvalidItemsMessage"
                       @dialog-closed="onDialogInvalidItemsClosed"
                       @remove-all-invalid-items="onRemoveAllInvalidItems" />
  <shop-header></shop-header>
  <el-main id="main-content" role="main" :aria-label="$t('accessibility.landmarks.main')">
    <el-breadcrumb class="el-breadcrumb--page">
      <el-breadcrumb-item :to="{ name: routesName.SHOP_HOME }">
        {{ $t('breadcrumb.home') }}
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        {{ $t('breadcrumb.cart') }}
      </el-breadcrumb-item>
    </el-breadcrumb>
    <cart-empty v-if="isCartEmpty" />
    <div v-else>
      <h1 class="h2 cart__title">{{ $t('cart.title') }}</h1>
      <div class="cart__wrapper">
        <cart-terms :is-mobile="true" @show-terms-dialog="termsAndConditionsDialogVisible = true" @go-to-checkout="validateCartBeforeCheckout" />
        <cart-items />
        <cart-summary @show-terms-dialog="termsAndConditionsDialogVisible = true" @go-to-checkout="validateCartBeforeCheckout" />
        <dialog-terms-and-conditions v-if="termsAndConditionsDialogVisible"
                                     :show-dialog="termsAndConditionsDialogVisible"
                                     @dialog-closed="termsAndConditionsDialogVisible = false" />
      </div>
    </div>
  </el-main>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, h } from 'vue'
import { useI18n } from 'vue-i18n'
import { useHead } from '@vueuse/head'
import { useCartStore } from '@/store/cart-store'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import { ROUTES_NAME, navigateToCheckout } from '@/router'
import { getTranslatedTextByKey } from '@/utils/i18n'
import { VALIDATE_PRICE_CART_ENABLED } from '@/utils/featureToggle'
import ShopHeader from '@/components/ShopHeader.vue'
import DialogInvalidCart from '@/components/Dialog/DialogInvalidCart.vue'
import DialogSessionTimedOut from '@/components/Dialog/DialogSessionTimedOut.vue'
import DialogTermsAndConditions from '@/components/Dialog/DialogTermsAndConditions.vue'
import CartTerms from '@/components/CartTerms.vue'
import CartEmpty from '@/components/CartEmpty.vue'
import CartItems from '@/components/CartItems.vue'
import CartSummary from '@/components/CartSummary.vue'

const { t } = useI18n()
const router = useRouter()
const routesName = ref(ROUTES_NAME)
const cartStore = useCartStore()

const termsAndConditionsDialogVisible = ref(false)
const sessionTimeoutMessageDialogVisible = ref(false)

const isCartEmpty = computed(() => !cartStore.hasItems)

const shouldValidateCart = computed(() => {
  return !isCartEmpty.value && VALIDATE_PRICE_CART_ENABLED()
})

const metaConfig = { // expose to unit tests
  title: getTranslatedTextByKey('seo.pages.cart.title'),
  description: getTranslatedTextByKey('seo.pages.cart.description'),
}

useHead({
  title: metaConfig.title,
  meta: [{
    name: 'description',
    content: metaConfig.description,
  }],
})

const onDialogSessionTimeoutClosed = () => {
  sessionTimeoutMessageDialogVisible.value = false
  router.go(0)
}

const onDialogInvalidItemsClosed = () => {
  cartStore.setShowInvalidItemsMessage(false)
}

const onRemoveAllInvalidItems = () => {
  cartStore.invalidItems.forEach((invalidItem) => {
    cartStore.removeItem(invalidItem.productId)
  })
  cartStore.clearInvalidItems()
  onDialogInvalidItemsClosed()
  if (!cartStore.canBypassInvalidItemsMessage && !isCartEmpty.value) {
    navigateToCheckout()
  }
}

const validateCartOnMounted = async() => {
  if (shouldValidateCart.value) {
    cartStore.setCanBypassInvalidItemsMessage(true)
    try {
      await doValidateCart()
    } catch (error) {
      showRequestFailedMessage()
    }
  }
}

const validateCartBeforeCheckout = async() => {
  if (shouldValidateCart.value) {
    cartStore.setCanBypassInvalidItemsMessage(false)
    try {
      await doValidateCart()
      if (!cartStore.hasInvalidItems) {
        navigateToCheckout()
      }
    } catch (error) {
      showRequestFailedMessage(true)
    }
  } else {
    navigateToCheckout()
  }
}

const showRequestFailedMessage = (showRefreshPageLink = false) => {
  const message = showRefreshPageLink
    ? h('div', { 'class': 'el-message__content' }, [
        h('div', null, t('notification.error-validate-cart.message')),
        h('a', { 'id': 'refreshPageLink' }, t('button.refresh-page')),
      ])
    : t('notification.error-validate-cart.message').toString()
  ElMessage({
    type: 'error',
    duration: 0,
    zIndex: 3000, // on top of dialogs,
    showClose: true,
    message,
  })

  if (showRefreshPageLink) {
    const refreshPageLink = document.getElementById('refreshPageLink');
    if (refreshPageLink) {
      refreshPageLink.onclick = () => {
        location.reload()
      }
    }
  }
}

const doValidateCart = async() => {
  try {
    return cartStore.validateCart()
  } catch (error) {
    throw error
  }
}

onMounted(async () => {
  sessionTimeoutMessageDialogVisible.value = !!cartStore.showSessionTimeoutMessage
  if (sessionTimeoutMessageDialogVisible.value) {
    cartStore.setShowSessionTimeoutMessage(false)
  } else {
    await validateCartOnMounted()
  }
})
</script>
