import { defineStore } from 'pinia'
import { AppState } from '@/store/types/app-state'

export const useAppStore = defineStore('appStore', {
  state: () => ({
    isGuestMode: true,
    showEcSidebar: true,
    starterKitId: '',
    starterKit: undefined
  } as AppState),
  actions: {
    setGuestMode(guestMode: boolean) {
      this.isGuestMode = guestMode
    },
    setShowEcSidebar(showEcSidebar: boolean) {
      this.showEcSidebar = showEcSidebar
    },
    setStarterKitId(starterKitId: string) {
      if (starterKitId && starterKitId !== '' && starterKitId !== this.starterKitId) {
        this.starterKitId = starterKitId
      }
    },
    setStarterKit(product: any){
      this.starterKit = product
    }
  },
})
