<template>
  <el-footer class="app-footer" role="contentinfo" :aria-label="$t('accessibility.landmarks.footer')">
    <div class="app-footer__inner">
      <div class="app-footer__top">
        <el-button type="primary" plain @click="navigateToContact">
          {{ $t('header-menu.contact-us') }}
        </el-button>
      </div>
      <div class="app-footer__bottom">
        <div class="app-footer__links">
          <a class="app-footer__link" @click="navigateToCancellationPolicy">
            {{ $t('header-menu.cancellation') }}
          </a>
          <a class="app-footer__link" :href="getUrlByKey('header-menu.url-legal')" target="_blank" rel="noopener noreferrer">
            {{ $t('header-menu.legal') }}
          </a>
          <a class="app-footer__link" :href="getUrlByKey('header-menu.url-privacy')" target="_blank" rel="noopener noreferrer">
            {{ $t('header-menu.privacy') }}
          </a>
          <a class="app-footer__link" @click="navigateToTermsConditions">
            {{ $t('header-menu.terms') }}
          </a>
        </div>
        <span>{{ $t('footer.copyright.part1') }} {{ year }}{{ $t('footer.copyright.part2') }}</span>
      </div>
    </div>
  </el-footer>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { DateTime } from 'luxon'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { navigateToContact, navigateToCancellationPolicy, navigateToTermsConditions } from '@/router'
import { getUrlByKey } from '@/utils/navigation'

const router = useRouter()
const { t } = useI18n()

const year = computed(() => DateTime.now().toFormat('yyyy'))
</script>
