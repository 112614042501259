import { onMounted, onUnmounted, ref } from 'vue'
import { throttle } from '@/utils/throttle'

const THROTTLE_TIMEOUT_VALUE = 100

export const onWindowResizeEvent = (callback: () => void) => {
  const windowWidth = ref(window.innerWidth)

  function handleResize() {
    if (window.innerWidth !== windowWidth.value) {
      windowWidth.value = window.innerWidth
      callback()
    }
  }
  onMounted(() => {
    window.addEventListener('resize', throttle(handleResize, THROTTLE_TIMEOUT_VALUE));
  })

  onUnmounted(() => {
    window.removeEventListener('resize', throttle(handleResize, THROTTLE_TIMEOUT_VALUE));
  })
}
