<template>
  <div class="product-ctas" :class="{ 'product-ctas--mobile-version': props.isMobile, 'product-ctas--is-sticky': isStickyMode }">
    <div class="product-ctas__buttons">
      <el-button
        @click="$emit('buy-now')"
        type="default"
        :disabled="!isInStock">{{ $t('button.buy') }}</el-button>
      <el-button
        @click="$emit('add-to-cart')"
        type="primary"
        :disabled="!isInStock">{{ $t('button.add-to-cart') }}</el-button>
    </div>
    <div :id="props.isMobile ? 'product-ctas-mobile' : ''"/>
  </div>
</template>

<script setup lang="ts">
import { handleMobileStickyMode } from '@/composables/use-mobile-sticky-mode'

type ProductCTAs = {
  isMobile: boolean,
  isInStock: boolean,
}

const props = withDefaults(defineProps<ProductCTAs>(), {
  isMobile: false,
  isInStock: true,
})

const emits = defineEmits(['buy-now', 'add-to-cart'])

const { isStickyMode } = handleMobileStickyMode('use-sticky-buy-drawer')
</script>
