<template>
  <a href="#main-content" class="skip-to-main-content-link">
    {{ $t('accessibility.skip-to-main-link') }}
  </a>
  <div class="app-main" id="app-main" :class="routeClassName" :style="isRouteCheckoutAndCollapseOpen ? { 'padding-bottom': summaryCollapseOpenPadding } : {}">
    <ec-unsupported-browsers />
    <el-container class="ec-container">
      <router-view />
      <app-footer />
    </el-container>
  </div>
</template>

<script setup lang="ts">
import { computed, watch, ref} from 'vue'
import { useHead } from '@vueuse/head'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { useCheckoutStore } from '@/store/checkout-store'
import { DEFAULT_LANGUAGE } from '@/utils/constants'
import EcUnsupportedBrowsers from '@/components/ec-unsupported-browsers/EcUnsupportedBrowsers.vue'
import AppFooter from '@/components/AppFooter.vue'
import { getTranslatedTextByKey } from '@/utils/i18n'

const route = useRoute()
const { t, locale } = useI18n({ useScope: 'global' })
const checkoutStore = useCheckoutStore()
const summaryCollapseOpenPadding = ref('0px')

const metaConfig = { // expose to unit tests
  title: getTranslatedTextByKey('seo.pages.home.title'),
  description: getTranslatedTextByKey('seo.pages.home.description'),
}

useHead({
  title: metaConfig.title,
  meta: [{
    name: 'description',
    content: metaConfig.description,
  }],
  htmlAttrs: {
    lang: computed(() => locale.value ?? DEFAULT_LANGUAGE),
  },
})

const routeClassName = computed(() => {
  if (route.name) {
    return 'route-' + route.name.toString().split(/(?=[A-Z])/).join('-').toLowerCase()
  }
  return ''
})

const isRouteCheckoutAndCollapseOpen = computed(() => {
  return routeClassName.value === 'route-checkout' && checkoutStore.getCheckoutSummaryCollapseOpen
})

watch(
  () => checkoutStore.getCheckoutSummaryCollapseOpen,
  () => {
      const TIMEOUT = 400
      setTimeout(() => {
      summaryCollapseOpenPadding.value = `${document.getElementById('checkoutSummary')?.clientHeight ?? 0}px`
    }, TIMEOUT)
  },
  { deep: true }
)
</script>
