<template>
  <el-breadcrumb class="el-breadcrumb--page">
    <el-breadcrumb-item :to="{ name: routesName.SHOP_HOME }">
      {{ $t('breadcrumb.home') }}
    </el-breadcrumb-item>
    <el-breadcrumb-item>
      {{ productName }}
    </el-breadcrumb-item>
  </el-breadcrumb>
</template>

<script setup lang="ts">
import { ROUTES_NAME } from '@/router'
import { ref } from 'vue'

const routesName = ref(ROUTES_NAME)

type ProductBreadcrumb = {
  productName?: string,
}

const props = defineProps<ProductBreadcrumb>()
</script>
