<template>
  <div class="cart-summary" role="complementary" :aria-label="$t('accessibility.landmarks.cart-summary')">
    <div class="cart-summary__header">
      <h2 class="cart-summary__header__title">{{ $t('components.cart-summary.title') }}</h2>
      <div class="cart-summary__header__subtotal">
        <h3>{{ $t('components.cart-summary.subtotal') }}</h3>
        <span v-number-format="{ number: cartStore.subtotal, locale: $i18n.locale }"></span>
      </div>
      <p class="cart-summary__header__subtitle">{{ $t('components.cart-summary.subtitle') }}</p>
    </div>
    <el-divider class="hidden-xs-only"></el-divider>
    <cart-terms :is-mobile="false" @show-terms-dialog="$emit('show-terms-dialog')" @go-to-checkout="$emit('go-to-checkout')" />
  </div>
</template>

<script setup lang="ts">
import { useCartStore } from '@/store/cart-store'
import CartTerms from '@/components/CartTerms.vue'

const cartStore = useCartStore()

const emits = defineEmits(['show-terms-dialog', 'go-to-checkout'])
</script>
