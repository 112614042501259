import { CartItem } from '@/domain/cart-item'
import { CheckoutItem } from '@/domain/checkout-item'

export const getCartItemPriceTotal = (item: CartItem): string => {
  return (Number(item.price) * item.quantitySelected).toFixed(2)
}

export const getCheckoutItemPriceTotal = (item: CheckoutItem): string => {
  return (Number(item.unitPrice) * item.quantity).toFixed(2)
}

export const getItemsSubtotal = (items: CartItem[]|CheckoutItem[], isCartItems: boolean = true): any => {
  return items.map((item: CartItem|CheckoutItem) => {
    const quantityPropertyName = isCartItems ? 'quantitySelected' : 'quantity'
    const pricePropertyName = isCartItems ? 'price' : 'unitPrice'
    const itemPrice = Number.parseFloat(item[pricePropertyName]) ?? 0
    return item[quantityPropertyName] * itemPrice
  }).reduce((a, b) => a + b, 0)
}
