import i18n from '@/i18n/i18n'
import { DEFAULT_LANGUAGE } from '@/utils/constants'

// @ts-ignore
const { t, te } = i18n.global

export const getTranslatedTextByKey = (key: string): string => {
  if (key && te(key)) {
    return t(key)
  }
  return key
}

export const getLocaleName = () => {
  return i18n.global.locale.value ?? DEFAULT_LANGUAGE
}
