import { defineStore } from 'pinia'
import { CheckoutState } from '@/store/types/checkout-state'
import { FORM_PAGES_IDS, DEFAULT_STORE_VALUES } from '@/utils/constants'
import { getItemsSubtotal } from '@/utils/pricing'
import { getDisplayUrlFromCartItem } from '@/utils/cart'
import { validatePriceCheckout } from '@/api/graph-ql/order-api'
import { CartItem } from '@/domain/cart-item'
import { CheckoutItem } from '@/domain/checkout-item'
import { DiscountItem } from '@/domain/discount-item'
import { TaxesItem } from '@/domain/taxes-item'
import { useCartStore } from '@/store/cart-store'
import { GqlPriceValidation } from '@/src/domain/__generated__/graph-ql-types'

export const useCheckoutStore = defineStore('checkoutStore', {
  state: () => ({
    loadingValidatePrice: false,
    formCurrentPageId: FORM_PAGES_IDS.SHIPPING,
    emailAddress: '',
    billingProvinceCode: '',
    isCheckoutSummaryCollapseOpen: false,
    discounts: DEFAULT_STORE_VALUES.CHECKOUT.DISCOUNTS,
    items: DEFAULT_STORE_VALUES.CHECKOUT.ITEMS,
    taxes: DEFAULT_STORE_VALUES.CHECKOUT.TAXES,
    subtotal: DEFAULT_STORE_VALUES.CHECKOUT.SUBTOTAL,
    total: DEFAULT_STORE_VALUES.CHECKOUT.TOTAL,
  } as CheckoutState),
  actions: {
    setLoadingValidatePrice(loadingValidatePrice: boolean) {
      this.loadingValidatePrice = loadingValidatePrice
    },
    setCheckoutSummaryCollapseOpen(isCheckoutSummaryCollapseOpen: boolean) {
      this.isCheckoutSummaryCollapseOpen = isCheckoutSummaryCollapseOpen
    },
    setFormCurrentPageId(formCurrentPageId: string) {
      this.formCurrentPageId = formCurrentPageId
    },
    setEmailAddress(emailAddress: string) {
      this.emailAddress = emailAddress
    },
    setBillingProvinceCode(billingProvinceCode: string) {
      this.billingProvinceCode = billingProvinceCode
    },
    setInitialStoreFromCart() {
      const cartStore = useCartStore()
      const items = convertCartItemsToCheckoutItems(cartStore.items)
      this.setItems(items)
      this.setSubtotal(getItemsSubtotal(items, false))
    },
    setItems(items: CheckoutItem[]) {
      this.items = items
    },
    clearItems() {
      this.items = DEFAULT_STORE_VALUES.CHECKOUT.ITEMS
    },
    setDiscounts(discounts: DiscountItem[]) {
      this.discounts = discounts
    },
    clearDiscounts() {
      this.discounts = DEFAULT_STORE_VALUES.CHECKOUT.DISCOUNTS
    },
    setTaxes(taxes: TaxesItem) {
      this.taxes = taxes
    },
    clearTaxes() {
      this.taxes = DEFAULT_STORE_VALUES.CHECKOUT.TAXES
    },
    setSubtotal(subtotal: number) {
      this.subtotal = subtotal
    },
    clearSubtotal() {
      this.subtotal = DEFAULT_STORE_VALUES.CHECKOUT.SUBTOTAL
    },
    setTotal(total: number) {
      this.total = total
    },
    clearTotal() {
      this.total = DEFAULT_STORE_VALUES.CHECKOUT.TOTAL
    },
    async validatePrice(): Promise<void> {
      this.setLoadingValidatePrice(true)
      return new Promise<void>((resolve, reject) => {
        const GqlObject: GqlPriceValidation = {
          emailAddress: this.emailAddress,
          billingProvinceCode: this.billingProvinceCode,
          // @ts-ignore
          productPriceItems: this.items,
        }
        const { onDone, onError } = validatePriceCheckout(GqlObject)
        onDone((result: any) => {
          const { productPriceItems, subtotal, total, discounts, taxes } = getValuesFromValidatePriceResponse(result)
          // update cart items
          const cartStore = useCartStore()
          cartStore.setItemsFromCheckout(productPriceItems)
          // update checkout items
          this.setItems(productPriceItems)

          if (this.hasItems) {
            this.setSubtotal(subtotal)
            this.setTotal(total)
            this.setDiscounts(discounts)
            this.setTaxes(taxes)
          } else {
            this.clearSubtotal()
            this.clearTotal()
            this.clearDiscounts()
            this.clearTaxes()
          }

          this.setLoadingValidatePrice(false)
          resolve()
        })

        onError((error: any) => {
          this.setLoadingValidatePrice(false)
          reject(error)
        })
      })
    },
  },
  getters: {
    hasItems(state) {
      return state.items.length
    },
    getCheckoutSummaryCollapseOpen(): boolean {
      return this.isCheckoutSummaryCollapseOpen
    }
  },
})

const getDiscountsFromResponse = (productPriceItems: CheckoutItem[]): DiscountItem[] => {
  return productPriceItems.filter((productPriceItem: CheckoutItem) => {
    return productPriceItem.discountReason && productPriceItem.discountReason !== ''
  }).map((productPriceItem: CheckoutItem) => {
    const discountItem: DiscountItem = {
      discount: productPriceItem.discount ?? 0,
      discountReason: productPriceItem.discountReason?.toString()! ?? ''
    }
    return discountItem
  }) ?? []
}

const getValuesFromValidatePriceResponse = (result: any) => {
  const responseValidatePrice = result?.data?.validatePriceCheckout
  const productPriceItems = responseValidatePrice?.productPriceItems.map((checkoutItem: CheckoutItem) => {
    delete checkoutItem['__typename']
    return checkoutItem
  }) ?? []
  const subtotal = responseValidatePrice?.subTotalPrice ?? 0
  const total = responseValidatePrice?.totalPrice ?? 0
  const discounts = getDiscountsFromResponse(productPriceItems)
  const taxes = responseValidatePrice?.tax ?? {}
  return { productPriceItems, subtotal, total, discounts, taxes }
}

const convertCartItemsToCheckoutItems = (cartItems: CartItem[]): CheckoutItem[] => {
  return cartItems.map((cartItem: CartItem) => {
    const item: CheckoutItem = {
      displayUrl: getDisplayUrlFromCartItem(cartItem),
      name: cartItem.name,
      pricebookEntryId: cartItem.pricebookEntryId,
      quantity: cartItem.quantitySelected,
      unitPrice: parseInt(cartItem.price)
    }
    return item
  })
}
