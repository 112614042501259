import { UseQueryReturn, useQuery } from '@vue/apollo-composable'
import { computed, ComputedRef, Ref } from 'vue'
import { GET_PRODUCTS, GET_PRODUCT } from './queries/product-queries'
import { GqlPageInfo, GqlProduct, GqlProducts } from '@/domain/__generated__/graph-ql-types'
import { OperationVariables } from '@apollo/client'
import { getLocaleName } from '@/utils/i18n'

const GQL_CATALOG_CODE = 'IoTMP_IoT_HW'

export interface GqlProductsResponse {
  __typename: String
  products: GqlProducts,
  pageInfo: GqlPageInfo,
  starterKitId: String
}

export interface GetProductsQueryReturn extends UseQueryReturn<GqlProductsResponse, OperationVariables> {
  data: {
    products: ComputedRef<GqlProduct[]>,
    totalCount: ComputedRef<Number>
  },
  loading: Ref<boolean>
}

export const getProductsData = (offset: Number, pageSize: Number, filterSortBy: String, filterDeviceType: String[], filterStock: String[]): GetProductsQueryReturn|any => {
  const queryParams = {
    offset,
    pagesize: pageSize,
    catalogCode: GQL_CATALOG_CODE,
    lang: getLocaleName(),
    orderBy: filterSortBy !== '' ? filterSortBy : null,
    typeFilter: filterDeviceType.length ? filterDeviceType : null,
    stockFilter: filterStock.length ? filterStock : null,
  }
  const { loading, result, refetch, onResult, onError, ...apolloQuery } = useQuery<GqlProductsResponse>(GET_PRODUCTS, queryParams)

  const productItems = computed<GqlProduct[]>(() => {
    if (result.value?.products?.productList) {
      return [...result.value.products.productList]
    }
    return []
  })

  const totalCount = computed<Number>(() => {
    if (result.value?.products?.pageInfo?.totalCount) {
      return result.value.products.pageInfo.totalCount
    }
    return 0
  })

  const starterKitId = computed<String>(() => {
    if (result.value?.products?.starterKitId) {
      return result.value.products.starterKitId
    }
    return ''
  })

  return {
    data: {
      products: productItems,
      totalCount,
      starterKitId,
    },
    loading,
    result,
    onResult,
    onError,
    refetch,
    ...apolloQuery
  }
}

export interface GqlProductResponse {
  __typename: String
  product: GqlProduct
}

export interface GetProductQueryReturn extends UseQueryReturn<GqlProductResponse, OperationVariables> {
  data: ComputedRef<GqlProduct>,
  loading: Ref<boolean>
}

export const getProductData = (id: string): GetProductQueryReturn|any => {
  const { loading, result, refetch, onResult, onError, ...apolloQuery } = useQuery<GqlProductResponse>(GET_PRODUCT, { catalogCode: GQL_CATALOG_CODE, offerCode: id, lang: getLocaleName() })

  const productItem = computed<GqlProduct>(() => {
    return result.value?.product ?? <GqlProduct>{}
  })

  return {
    data: productItem,
    loading,
    result,
    onResult,
    onError,
    refetch,
    ...apolloQuery
  }
}

export interface GqlRecommendedAddonsResponse {
  __typename: String
  products: GqlProducts,
  pageInfo: GqlPageInfo
}

export interface GetRecommendedAddonsQueryReturn extends UseQueryReturn<GqlRecommendedAddonsResponse, OperationVariables> {
  data: {
    products: ComputedRef<GqlProduct[]>,
    totalCount: ComputedRef<Number>
  },
  loading: Ref<boolean>
}
