<template>
  <div v-for="product in productDetailsItems">
    <p><strong>{{product.label}}</strong></p>
    <p>{{product.value}}</p>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useAppStore } from '@/store/app-store'
import { navigateToProductDetails } from '@/router'
import {useProductStore} from "@/store/product-store";
import {useI18n} from "vue-i18n";
const { t } = useI18n()
const productStore = useProductStore()
const CONNECTIVITY_TYPE_CODE = 'CS-3063'
const LEAD_TIME_OUT_OF_STOCK ='CS-3155'
const LEAD_TIME_IN_STOCK = 'CS-3154'
const ESTIMATED_DELIVERY_TIME = productStore?.product?.inventoryLevel ==='IN_STOCK' ? LEAD_TIME_IN_STOCK: LEAD_TIME_OUT_OF_STOCK

const productDetailsItems = computed (() => {
  const products =  productStore?.product?.productAttributes
    ?.filter(attr => attr.value && attr.value !== 'NA')
  const productDetails: string[] = []
  const connectivityAttr = products.find(attr => attr.code === CONNECTIVITY_TYPE_CODE)
  const estimatedDeliveryTimeAttr = products.find(attr => attr.code === ESTIMATED_DELIVERY_TIME)
  if (connectivityAttr && productStore?.product?.family === 'Device'){
    productDetails.push(connectivityAttr)
  }
  if (estimatedDeliveryTimeAttr){
    const estimatedDeliveryTimeAttrClone = {...estimatedDeliveryTimeAttr}
    estimatedDeliveryTimeAttrClone.label = t('components.product-attributes.estimated-delivery-date')
    productDetails.push(estimatedDeliveryTimeAttrClone)
  }
  return productDetails
})

</script>
