import { onMounted, onUnmounted, ref } from 'vue'
import { throttle } from '@/utils/throttle'

const THROTTLE_TIMEOUT_VALUE = 50

export const handleMobileStickyMode = (bodyClass: string = '') => {
  const isStickyMode = ref(false)

  function handleStickyMode() {
    if (window.innerWidth >= 768) {
      document.body.classList.remove(bodyClass);
      isStickyMode.value = false;
      return
    }

    document.body.classList.add(bodyClass);
    isStickyMode.value = true;
  }

  onMounted(() => {
    window.addEventListener('scroll', throttle(handleStickyMode, THROTTLE_TIMEOUT_VALUE));
    window.addEventListener('resize', throttle(handleStickyMode, THROTTLE_TIMEOUT_VALUE));
  })

  onUnmounted(() => {
    window.removeEventListener('scroll', throttle(handleStickyMode, THROTTLE_TIMEOUT_VALUE));
    window.removeEventListener('resize', throttle(handleStickyMode, THROTTLE_TIMEOUT_VALUE));
  })

  return { isStickyMode }
}
