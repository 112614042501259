export const insertScriptTag = async (uri: string): Promise<void> => {
  return new Promise<void>((resolve) => {
    const tag = document.createElement('script')
    tag.src = uri
    tag.async = true
    tag.onload = () => {
      resolve()
    }
    document.body.appendChild(tag)
  })
}
