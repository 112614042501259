import { CheckoutItem } from '@/domain/checkout-item'
import { OrderItem } from '@/domain/order-item'
import { NeoFormOptions } from 'rky-neo-form-lib-utils'
import { insertScriptTag } from '@/utils/loadThirdParty'
import { convertToModel } from '@/utils/formConverter'
import { getCreditCardType } from '@/utils/creditCard'
import {isProductionEnvironment} from "@/utils/environments";

export const EMPTY_FORM_DEFINITION = {
  id: '',
  name: '',
  pages: [],
  title: {
    en: '',
    fr: '',
  },
  resultEntity: '',
  exportReviewAsPdf: false,
}

export const NEOFORM_OPTIONS: NeoFormOptions = {
  canUseDraft: false,
  canUseTemplate: false,
  canBackOnFirstStep: true,
  canShowSteps: true,
  canShowReviewPage: true,
  shouldHandlePageForward: false,
  // @ts-ignore
  showReviewPageTitle: true,
  reviewPageTitle: {
    en: 'Review and place order',
    fr: 'Vérifier et commander'
  },
  formActionButtonsLabel: {
    backLeave: {
      en: 'Return to cart',
      fr: 'Retour au panier',
    },
    continueByPageIndex: [
      {
        en: 'Continue to billing & payment',
        fr: 'Passer à la facturation et au paiement'
      },
      {
        en: 'Continue to review',
        fr: 'Passer à la vérification',
      },
    ],
    submit: {
      en: 'Place order',
      fr: 'Passer la commande',
    },
  },
}

export const fetchDtsScripts = async () => {
  const datetime = new Date()
  let baseUrl = 'https://pcidts-qa.int.bell.ca:51443'
  let dtsPlugin = 'dtsPluginEnc_v3.js'

  if(isProductionEnvironment()){
    baseUrl = 'https://pcidts.bell.ca:443'
    dtsPlugin = 'dtsPlugin_v3.1.js'
  }

  await insertScriptTag(`${baseUrl}/dtsApp/dtsOnline/scripts/jquery-3.1.1.js`)
  await insertScriptTag(`${baseUrl}/dtsApp/dtsOnline/scripts/jquery.jsonp-2.4.0.js`)
  await insertScriptTag(`${baseUrl}/dtsApp/dtsOnline/scripts/` + dtsPlugin + `?${datetime.getTime()}`)
}

export const getInitialOrder = (payload: any, dtsTempCcValue: string, checkoutStoreItems: CheckoutItem[]) => {
  const checkoutPayload = convertToModel(payload)
  let billingAddress: any
  const creditCardType = getCreditCardType(dtsTempCcValue)
  const creditCardLast4Digits = dtsTempCcValue.slice(-4)

  if(!checkoutPayload.hasOwnProperty('billingAddress')){
    billingAddress = checkoutPayload['shippingAddress']
  }

  if (checkoutPayload.hasOwnProperty('paymentInformation')) {
    checkoutPayload['paymentInformation'].creditCardToken = ''
    checkoutPayload['paymentInformation'].creditCardLast4Digits = creditCardLast4Digits
    if (creditCardType) {
      checkoutPayload['paymentInformation'].creditCardType = creditCardType
    }
  }

  return {
    billingAddress,
    ...checkoutPayload,
    itemList: checkoutStoreItems.map((item: CheckoutItem) => generateApiOrderItem(item))
  }
}

export const getOrderWithCcToken = (createOrder: any, token: string = '') => {
  if (createOrder.hasOwnProperty('paymentInformation')) {
    const createOrderCopy = {
      ...createOrder
    }
    createOrderCopy['paymentInformation'].creditCardToken = token
    return createOrderCopy
  }
  return createOrder
}

export const generateApiOrderItem = (item: CheckoutItem): OrderItem => {
  const orderItem: OrderItem = {
    pricebookEntryId: item.pricebookEntryId,
    quantity: item.quantity,
    unitPrice: item.unitPrice,
    // @ts-ignore
    totalPrice: item.totalPrice,
  }

  if (item.discount) {
    orderItem.discount = item.discount
  }

  return orderItem
}

export const getCheckoutSummaryHeight = () => {
  const domElem = document.getElementById('checkoutSummary')
  let height = '0'
  if (window.innerWidth > 767 && (domElem && domElem.clientHeight)) {
    height = (domElem.clientHeight + 160) + 'px'
  }
  return height
}
