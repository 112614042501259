<template>
  <el-table :key="componentKey" :data="items" class="cart-items__table">
    <el-table-column :label="$t('components.cart-items.columns.item')" width="280">
      <template #default="scope">
        <router-link
          :to="`/product/${scope.row.id}`"
          class="cart-items__desc">
          <product-image :attachments="scope.row.attachments" :product-name="scope.row.name" class="cart-items__desc__image" />
          <span class="cart-items__desc__title">{{ scope.row.name }}</span>
        </router-link>
      </template>
    </el-table-column>
    <el-table-column :label="$t('components.cart-items.columns.sku')">
      <template #default="scope">
        <router-link
            :to="`/product/${scope.row.id}`"
            class="cart-items__desc">
          {{ scope.row.id }}
        </router-link>
      </template>
    </el-table-column>
    <el-table-column :label="$t('components.cart-items.columns.quantity')" width="120">
      <template #default="scope">
        <product-quantity
          :quantity-selected="scope.row.quantitySelected"
          :show-label="false"
          :is-small-layout="true"
          @change="(newQuantity) => cartStore.updateItemQuantity(scope.row.id, newQuantity)">
        </product-quantity>
      </template>
    </el-table-column>
    <el-table-column :label="$t('components.cart-items.columns.price')" align="right" width="100">
      <template #default="scope">
        <span class="cart-items__price" v-number-format="{ number: scope.row.price, locale: $i18n.locale }"></span>
      </template>
    </el-table-column>
    <el-table-column :label="$t('components.cart-items.columns.total')" align="right" width="125">
      <template #default="scope">
        <span class="cart-items__price" v-number-format="{ number: getCartItemPriceTotal(scope.row), locale: $i18n.locale }"></span>
      </template>
    </el-table-column>
    <el-table-column fixed="right" width="65">
      <template #header>
        <span class="sr-only">{{ $t('components.cart-items.columns.actions') }}</span>
      </template>
      <template #default="scope">
        <el-button :icon="TrashIcon" class="cart-items__delete-button" @click="cartStore.removeItem(scope.row.id)">
          <span class="sr-only">{{ $t('components.cart-items.delete-button-alt') }}</span>
        </el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useCartStore } from '@/store/cart-store'
import { getCartItemPriceTotal } from '@/utils/pricing'
import ProductImage from '@/components/ProductImage.vue'
import ProductQuantity from '@/components/ProductQuantity.vue'
import TrashIcon from '@/components/icons/TrashIcon.vue'

const cartStore = useCartStore()
const { items } = storeToRefs(cartStore)

const componentKey = ref(0)

watch(items, () => {
  componentKey.value++
})
</script>
