import { ProductAttribute } from '@/domain/product-attribute'
import { GqlProductAttributes } from '@/domain/__generated__/graph-ql-types'

const graphQlToProductAttributeAdapter = (data: GqlProductAttributes): ProductAttribute => {
  return {
    code: data.code?.toString() ?? '',
    label: data.label?.toString() ?? '',
    value: data.value?.toString() ?? '',
    showInMarketplace: data.showInMarketplace ?? false,
  }
}

export {
  graphQlToProductAttributeAdapter
}
