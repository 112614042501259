<template>
  <div id="embedded-sandbox" style="height: 100vh;" />
</template>

<script setup lang="ts">
import { ApolloSandbox } from '@apollo/sandbox'
import { onMounted } from "vue";

// Hooks
onMounted(() => {
  new ApolloSandbox({
    target: '#embedded-sandbox',
    initialEndpoint: 'http://belleclf-local:9090',
    initialState: {
      document: `query ExampleQuery {
  id
}`,
      variables: {},
      headers: {},
    },
    includeCookies: false,
  })
})
</script>
