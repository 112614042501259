import axios, { AxiosPromise } from 'axios'

export default {
  getFormSchema(formName: string): AxiosPromise {
    return axios({
      method: 'GET',
      url: `/shop/form/${formName}.json`,
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
}
