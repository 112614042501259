const URL = '{env}.ecsso.ms.bell.ca'
const KNOWN_ENVS = ['int', 'qa', 'uat']

const getEnvironment = ():string | undefined => {
  let environment
  const hostname = window.location.hostname
  if (hostname.indexOf('local') !== -1) {
    environment = 'loc'
  } else {
    environment = KNOWN_ENVS.find(env => {
      return hostname === URL.replace('{env}', env)
    })
  }
  return environment
}

const isProductionEnvironment = ():boolean => {
  return window.location.host === 'iotmarketplace.bell.ca'
}

export {
  getEnvironment,
  isProductionEnvironment,
}
