import { createRouter, createWebHistory, RouteLocationNormalized, RouteRecordRaw } from 'vue-router'
import { ElMessage } from 'element-plus'
import ShopHome from '@/views/ShopHome.vue'
import ProductDetails from '@/views/ProductDetails.vue'
import ShoppingCart from '@/views/ShoppingCart.vue'
import Checkout from '@/views/Checkout.vue'
import Contact from '@/views/static/Contact.vue'
import CancellationPolicy from '@/views/static/CancellationPolicy.vue'
import TermsConditions from '@/views/static/TermsConditions.vue'
import GqlSandbox from '@/views/GqlSandbox.vue'

export const ROUTES_NAME = {
  SHOP_HOME: 'ShopHome',
  PRODUCT_DETAILS: 'ProductDetails',
  SHOPPING_CART: 'ShoppingCart',
  CHECKOUT: 'Checkout',
  CONTACT: 'Contact',
  CANCELLATION_POLICY: 'CancellationPolicy',
  TERMS_CONDITIONS: 'TermsConditions',
  GQL_SANDBOX: 'GqlSandbox',
}

export const ROUTES_PATH = {
  SHOP_HOME: '/',
  PRODUCT_DETAILS: '/product/:id',
  SHOPPING_CART: '/cart',
  CHECKOUT: '/checkout',
  CONTACT: '/contact',
  CANCELLATION_POLICY: '/cancellation-policy',
  TERMS_CONDITIONS: '/terms-conditions',
  GQL_SANDBOX: '/gql-sandbox',
}

export const routes: Array<RouteRecordRaw> = [
  {
    path: ROUTES_PATH.SHOP_HOME,
    name: ROUTES_NAME.SHOP_HOME,
    component: ShopHome
  },
  {
    path: ROUTES_PATH.PRODUCT_DETAILS,
    name: ROUTES_NAME.PRODUCT_DETAILS,
    component: ProductDetails,
  },
  {
    path: ROUTES_PATH.SHOPPING_CART,
    name: ROUTES_NAME.SHOPPING_CART,
    component: ShoppingCart,
    props: true,
  },
  {
    path: ROUTES_PATH.CHECKOUT,
    name: ROUTES_NAME.CHECKOUT,
    component: Checkout,
    beforeEnter: (to, from) => {
      if (from.name === ROUTES_NAME.SHOPPING_CART) {
        return true
      }
      navigateToCart()
      return false
    },
  },
  {
    path: ROUTES_PATH.CONTACT,
    name: ROUTES_NAME.CONTACT,
    component: Contact,
  },
  {
    path: ROUTES_PATH.CANCELLATION_POLICY,
    name: ROUTES_NAME.CANCELLATION_POLICY,
    component: CancellationPolicy,
  },
  {
    path: ROUTES_PATH.TERMS_CONDITIONS,
    name: ROUTES_NAME.TERMS_CONDITIONS,
    component: TermsConditions,
  },
  {
    path: ROUTES_PATH.GQL_SANDBOX,
    name: ROUTES_NAME.GQL_SANDBOX,
    component: GqlSandbox,
    beforeEnter: (to, from) => {
      if (process.env.NODE_ENV === 'production') {
        navigateToHome()
        return false
      }
      return true
    },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: () => {
      return '/'
    }
  }
]

const initRouterLogic = () => {
  router.beforeEach(async (to: RouteLocationNormalized, from: RouteLocationNormalized, next) => {
    if (to.name === ROUTES_NAME.SHOPPING_CART) {
      ElMessage.closeAll()
    }
    return next()
  })
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 }
  },
})

export const navigateToHome = () => {
  router.push({ name: ROUTES_NAME.SHOP_HOME })
}

export const navigateToProductDetails = (productId: string = '') => {
  if (productId !== '') {
    router.push({ name: ROUTES_NAME.PRODUCT_DETAILS, params: { id: productId } })
  }
}

export const navigateToCart = () => {
  router.push({ name: ROUTES_NAME.SHOPPING_CART })
}

export const navigateToCheckout = () => {
  router.push({ name: ROUTES_NAME.CHECKOUT })
}

export const navigateToContact = () => {
  router.push({ name: ROUTES_NAME.CONTACT })
}

export const navigateToCancellationPolicy = () => {
  router.push({ name: ROUTES_NAME.CANCELLATION_POLICY })
}

export const navigateToTermsConditions = () => {
  router.push({ name: ROUTES_NAME.TERMS_CONDITIONS })
}

initRouterLogic()

export default router
