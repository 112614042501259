<template>
  <shop-header></shop-header>
  <el-main class="cancellation-policy static-page" id="main-content" role="main" :aria-label="$t('accessibility.landmarks.main')">
    <h1 class="static-page__title">{{ $t('pages.cancellation-policy.title') }}</h1>
    <div class="static-page__content">
      <h2 class="static-page__section-title">{{ $t('pages.cancellation-policy.section-1-title') }}</h2>
      <p class="static-page__section-text">{{ $t('pages.cancellation-policy.section-1-text-1') }}</p>
      <h2 class="static-page__section-title">{{ $t('pages.cancellation-policy.section-2-title') }}</h2>
      <p class="static-page__section-text">{{ $t('pages.cancellation-policy.section-2-text') }}</p>
      <h2 class="static-page__section-title">{{ $t('pages.cancellation-policy.section-3-title') }}</h2>
      <p class="static-page__section-text">{{ $t('pages.cancellation-policy.section-3-text') }}</p>
    </div>
  </el-main>
</template>

<script setup lang="ts">
import { useHead } from '@vueuse/head'
import { useI18n } from 'vue-i18n'
import { getTranslatedTextByKey } from '@/utils/i18n'
import ShopHeader from '@/components/ShopHeader.vue'

const { t } = useI18n()

const metaConfig = { // expose to unit test
  title: getTranslatedTextByKey('seo.pages.cancellation.title'),
  description: getTranslatedTextByKey('seo.pages.cancellation.description'),
}

useHead({
  title: metaConfig.title,
  meta: [{
    name: 'description',
    content: metaConfig.description,
  }],
})
</script>
