export const convertToModel = (payload: any) => {
  const result = payload?.result
  const resultModel = {}

  if (result) {
    Object.values(result).forEach((r: any) => {
      if (r.resultPath) {
        parseResultPath(resultModel, r.resultPath.split('.'), r.value)
      }
    })
  }

  return resultModel
}

const parseResultPath = (resultObject: any, resultPathParts: any, value: any) => {
  const firstElement = resultPathParts?.shift()
  const isLastElement = resultPathParts.length === 0

  if (firstElement && !resultObject[firstElement]) {
    resultObject[firstElement] = isLastElement ? value : {}
    parseResultPath(resultObject[firstElement], resultPathParts, value)
  } else if (firstElement && resultObject[firstElement]) {
    parseResultPath(resultObject[firstElement], resultPathParts, value)
  }
}
