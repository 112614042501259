import { Attachment } from '@/domain/attachment'

export const getAttachmentUrl = (url: any): string => {
  if (url && url.startsWith('/') && !url.startsWith('/zuul/shop-api')) {
    return `/zuul/shop-api${url}`
  }
  return url
}

export const getDefaultAttachmentIndex = (attachments: Attachment[]): number => {
  const index = attachments.findIndex(attachment => attachment.defaultAttachment)
  return index !== -1 ? index : 0
}
