<template>
  <el-alert class="product-catalog-message"
            type="info"
            :closable="false">
    <template #title>
      {{ $t('components.product-catalog.no-products') }}
      <span v-if="hasSelectedFilters">
        {{ $t('components.product-catalog.clear-filters-1') }}
        <el-link @click="onClearFilters">{{ $t('components.product-catalog.clear-filters-2') }}</el-link>.
      </span>
    </template>
  </el-alert>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useProductStore } from '@/store/product-store'
import { useI18n } from 'vue-i18n'

const productStore = useProductStore()
const { t } = useI18n()

const hasSelectedFilters = computed(() => {
  return productStore.selectedFiltersLength > 0
})

const onClearFilters = () => {
  productStore.clearFilters()
  productStore.clearOffset()
  productStore.clearCurrentPage()
  productStore.fetchProducts()
}
</script>
