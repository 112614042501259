<template>
  <dialog-checkout-inactivity
    v-if="displayInactivityDialog"
    :timeout-minutes="HALF_TIMEOUT_MINUTES"
    @continue-session="onContinueSession"
    @clear-session="onClearSession"
  />
</template>

<script setup lang="ts">
import { computed, ref, watch, onMounted, onUnmounted } from 'vue'
import { useCartStore } from '@/store/cart-store'
import { DateTime } from 'luxon'
import DialogCheckoutInactivity from '@/components/Dialog/DialogCheckoutInactivity.vue'
import { navigateToCart } from '@/router'

const cartStore = useCartStore()

const FULL_TIMEOUT_MINUTES = 10
const HALF_TIMEOUT_MINUTES = FULL_TIMEOUT_MINUTES / 2
const activityEvents = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart']
const dateTimeNow = ref(DateTime.local())
const dateTimeEnd = ref(DateTime.local().plus({ minutes: FULL_TIMEOUT_MINUTES }))
const dateTimeHalf = ref(DateTime.local().plus({ minutes: HALF_TIMEOUT_MINUTES }))
const timeoutTick = ref()
const displayInactivityDialog = ref(false)

const isHalfTimeoutPassed = computed(() => {
  return dateTimeNow.value >= dateTimeHalf.value
})

const onContinueSession = () => {
  displayInactivityDialog.value = false
  resetEndTimeout()
  addEventListeners()
  startTimeoutTick()
}

const onClearSession = () => {
  clearTimeoutTick()
  displayInactivityDialog.value = false
  cartStore.setShowSessionTimeoutMessage(true)
  navigateToCart()
}

const resetEndTimeout = () => {
  dateTimeNow.value = DateTime.local()
  dateTimeEnd.value = DateTime.local().plus({ minutes: FULL_TIMEOUT_MINUTES })
  dateTimeHalf.value = DateTime.local().plus({ minutes: HALF_TIMEOUT_MINUTES })
}

const onActivityEvent = () => {
  if (!isHalfTimeoutPassed.value) {
    resetEndTimeout()
  }
}

const clearTimeoutTick = () => {
  clearInterval(timeoutTick.value)
}

const startTimeoutTick = () => {
  timeoutTick.value = setInterval(() => {
    dateTimeNow.value = DateTime.local()
  }, 100)
}

const addEventListeners = () => {
  activityEvents.forEach((event) => document.addEventListener(event, onActivityEvent, true))
}

const removeEventListeners = () => {
  activityEvents.forEach((event) => document.removeEventListener(event, onActivityEvent, true))
}

watch(dateTimeNow, () => {
  if (isHalfTimeoutPassed.value) {
    clearTimeoutTick()
    displayInactivityDialog.value = true
  }
})

onMounted(() => {
  cartStore.setShowSessionTimeoutMessage(false)
  resetEndTimeout()
  addEventListeners()
  startTimeoutTick()
})

onUnmounted(() => {
  removeEventListeners()
})
</script>
