<template>
  <shop-header></shop-header>
  <el-main class="contact static-page" id="main-content" role="main" :aria-label="$t('accessibility.landmarks.main')">
    <h1 class="static-page__title">{{ $t('pages.contact.title') }}</h1>
    <p class="static-page__subtitle">{{ $t('pages.contact.subtitle') }}</p>
    <el-divider class="hidden-sm-and-up"></el-divider>
    <div class="static-page__content">
      <h2 class="static-page__section-title">{{ $t('pages.contact.section-1-title') }}</h2>
      <p class="static-page__section-text" v-html="$t('pages.contact.section-1-text')" />

      <h2 class="static-page__section-title">{{ $t('pages.contact.section-2-title') }}</h2>
      <p class="static-page__section-text" v-html="$t('pages.contact.section-2-text')" />

      <h2 class="static-page__section-title">{{ $t('pages.contact.section-3-title') }}</h2>
      <p class="static-page__section-text contact__phone" v-html="$t('pages.contact.section-3-text-1')" />
      <p class="static-page__section-text static-page__section-text--grey">
        {{ $t('pages.contact.section-3-text-3') }}
      </p>
    </div>
  </el-main>
</template>

<script setup lang="ts">
import { useHead } from '@vueuse/head'
import { getTranslatedTextByKey } from '@/utils/i18n'
import ShopHeader from '@/components/ShopHeader.vue'

const metaConfig = { // expose to unit tests
  title: getTranslatedTextByKey('seo.pages.contact.title'),
  description: getTranslatedTextByKey('seo.pages.contact.description'),
}

useHead({
  title: metaConfig.title,
  meta: [{
    name: 'description',
    content: metaConfig.description,
  }],
})
</script>
