<template>
  <ul class="checkout-summary-items">
    <li v-for="item in checkoutStore.items" class="checkout-summary-items__item">
      <div class="checkout-summary-items__info">
        <product-image :image-url="item.displayUrl" :product-name="item.name" class="checkout-summary-items__image" />
        <div class="checkout-summary-items__text">
          <div class="checkout-summary-items__title">{{ item.name }}</div>
          <div class="checkout-summary-items__quantity">{{ $t('components.checkout-summary-items.item-count', item.quantity) }}</div>
        </div>
      </div>
      <span class="checkout-summary-items__price" v-number-format="{ number: getCheckoutItemPriceTotal(item), locale: $i18n.locale }" />
    </li>
  </ul>
</template>

<script setup lang="ts">
import { useCheckoutStore } from '@/store/checkout-store'
import ProductImage from '@/components/ProductImage.vue'

const checkoutStore = useCheckoutStore()

import { getCheckoutItemPriceTotal } from '@/utils/pricing'
</script>
