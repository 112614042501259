<template>
  <div v-if="productSuggestionList.length" class="product-details__inner">
    <div class="product-details__related">
      <div>
        <h2 class="product-details__section section-title">{{ $t('components.product-details.related.title') }}</h2>
        <div>
          <el-row :gutter="30">
            <el-col
              :sm="8"
              v-for="suggestion in productSuggestionList"
              :key="suggestion.id">
              <product-catalog-item v-bind="suggestion"></product-catalog-item>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useProductStore } from '@/store/product-store'
import ProductCatalogItem from '@/components/ProductCatalogItem.vue'

const productStore = useProductStore()

const productSuggestionList = computed(() => {
  return productStore.product?.suggestionList?.slice(0, 3) ?? []
})
</script>
