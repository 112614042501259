<template>
  <el-dialog v-model="shouldShowDialog"
             class="dialog-invalid-cart"
             width="90%"
             :title="$t('dialog.invalid-cart.title')"
             :show-close="cartStore.canBypassInvalidItemsMessage"
             :close-on-click-modal="cartStore.canBypassInvalidItemsMessage"
             :close-on-press-escape="cartStore.canBypassInvalidItemsMessage"
             @closed="$emit('dialog-closed')">
    <div class="dialog-invalid-cart__top">
      <h3>{{ $t('dialog.invalid-cart.title') }}</h3>
      <p>{{ $t('dialog.invalid-cart.subtitle') }}</p>
    </div>
    <div class="dialog-invalid-cart__items">
      <el-scrollbar tag="div" wrap-class="el-dialog__wrap" :lock-scroll="true">
        <ul>
          <li v-for="invalidItem in cartStore.invalidItems" class="dialog-invalid-cart__item">
            <product-image :image-url="invalidItem.displayUrl" :product-name="invalidItem.name" class="dialog-invalid-cart__item__image" />
            <div class="dialog-invalid-cart__item__info">
              <div class="dialog-invalid-cart__item__name">{{ invalidItem.name }}</div>
              <div class="dialog-invalid-cart__item__sku">
                {{ $t('dialog.invalid-cart.sku') }}: {{ invalidItem.productId }}
              </div>
              <div class="dialog-invalid-cart__item__quantity">
                {{ $t('dialog.invalid-cart.item-count', invalidItem.quantity) }}
              </div>
              <div class="dialog-invalid-cart__item__price"
                   v-number-format="{ number: invalidItem.unitPrice, locale: $i18n.locale }" />
            </div>
          </li>
        </ul>
      </el-scrollbar>
    </div>
    <div class="dialog-invalid-cart__footer">
      <el-button type="primary" @click="$emit('remove-all-invalid-items')">{{ $t('button.remove-all') }}</el-button>
    </div>
  </el-dialog>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useCartStore } from '@/store/cart-store'
import ProductImage from '@/components/ProductImage.vue'

type DialogSessionTimedOut = {
  showDialog: boolean,
}

const props = withDefaults(defineProps<DialogSessionTimedOut>(), {
  showDialog: false,
})

const cartStore = useCartStore()

const emits = defineEmits(['dialog-closed'])

const shouldShowDialog = computed(() => props.showDialog ?? false)
</script>
