import { GqlAttachment } from '@/domain/__generated__/graph-ql-types'
import { Attachment } from '@/src/domain/attachment'

const DEFAULT_SEQUENCE_NUMBER = 100

const graphQlToAtachmentAdapter = (data: GqlAttachment): Attachment => {
  return {
    contentType: data.contentType ?? '',
    defaultAttachment: data.defaultAttachment ?? false,
    description: data.description ?? '',
    sequenceNumber: data.sequenceNumber ?? DEFAULT_SEQUENCE_NUMBER.toString(),
    title: data.title ?? '',
    url: data.url ?? '',
  }
}

const graphQlToAtachmentsAdapter = (gqlAttachments: GqlAttachment[] = []): Attachment[] => {
  let attachments: Attachment[] = []
  if (gqlAttachments) {
    attachments = gqlAttachments.map(attachment => graphQlToAtachmentAdapter(attachment))
  }
  return sortAttachmentsBySequenceNumber(attachments)
}

const sortAttachmentsBySequenceNumber = (attachments: Attachment[] = []): Attachment[] => {
  return attachments.sort((a: Attachment, b: Attachment) => parseFloat(a.sequenceNumber) - parseFloat(b.sequenceNumber))
}

export {
  graphQlToAtachmentAdapter,
  graphQlToAtachmentsAdapter,
}
