<template>
  <div class="product-catalog-search" role="search" :aria-label="$t('accessibility.landmarks.search')">
    <el-input
      v-model="searchInput"
      @change="onInputChange"
      @clear="onInputClear"
      :suffix-icon="Search"
      clearable
      :placeholder="$t('components.product-catalog-search.placeholder')" />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { Search } from '@element-plus/icons-vue'
import { useProductStore } from '@/store/product-store'

const productStore = useProductStore()

const searchInput = ref('')

const onInputChange = (value: string) => {
  productStore.setSearchedProductName(value)
  productStore.fetchProducts()
}

const onInputClear = () => {
  productStore.clearSearchedProductName()
  productStore.fetchProducts()
}
</script>
