<template>
  <div class="product-catalog-single-banner" role="banner" :aria-label="$t('accessibility.landmarks.product-banner')">
    <div class="product-catalog-single-banner__inner">
      <div v-if="isLoading" class="product-catalog-single-banner__image product-catalog-single-banner__image--loading" />
      <img v-show="!isLoading" :src="imageUrl" alt="Bell SIM card" class="product-catalog-single-banner__image" @load="isLoading = false" @error="isLoading = false" />
      <div class="product-catalog-single-banner__headline">

        <template v-if="starterKitId !== ''">
          <h1 class="product-catalog-single-banner__title">
            {{ $t('components.product-catalog-single-banner.title') }}
          </h1>
          <p class="product-catalog-single-banner__subtitle">
            {{ $t('components.product-catalog-single-banner.subtitle') }}
          </p>
          <el-button class="hidden-sm-and-up not-underlined" link @click="onGoToStarterKit">
            {{ $t('button.order') }}
            <el-icon class="el-icon--right"><ec-lg-arrow-link-right /></el-icon>
          </el-button>
          <el-button class="hidden-xs-only" type="primary" @click="onGoToStarterKit">
            {{ $t('button.order') }}
          </el-button>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useAppStore } from '@/store/app-store'
import { navigateToProductDetails } from '@/router'
import { EcLgArrowLinkRight } from '@ec/icons'

const appStore = useAppStore()

const isLoading = ref(true)

const imageUrl = computed(() => {
  return `${import.meta.env.BASE_URL}/img/home-carousel-sim.png`
})

const starterKitId = computed(() => {
  return appStore.starterKitId.toString()
})

const starterKitLabel = computed(() =>{
  return appStore.starterKit?.name
})

const onGoToStarterKit = () => {
  navigateToProductDetails(starterKitId.value)
}
</script>
