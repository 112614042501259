<template>
  <el-dialog v-model="shouldShowDialog"
             class="dialog-terms-and-conditions"
             @close="$emit('dialog-closed')"
             :title="$t('dialog.terms-and-conditions.dialog-title')">
    <el-scrollbar tag="div" wrap-class="el-dialog__wrap" :lock-scroll="true">
      <div class="dialog-terms-and-conditions__top">
        <p class="dialog-terms-and-conditions__subtitle">{{ $t('dialog.terms-and-conditions.title') }}</p>
        <p class="dialog-terms-and-conditions__subtitle">{{ $t('dialog.terms-and-conditions.subtitle') }}</p>
      </div>
      <static-html-content v-for="(element, idx) in staticHtmlElements"
                           :key="'element-' + idx"
                           :element="element" />
    </el-scrollbar>
  </el-dialog>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import StaticHtmlContent from '@/components/StaticHtmlContent.vue'

type DialogTermsAndConditions = {
  showDialog: boolean,
}

const props = withDefaults(defineProps<DialogTermsAndConditions>(), {
  showDialog: false,
})

const { tm } = useI18n()
const emits = defineEmits(['dialog-closed'])

const shouldShowDialog = computed(() => props.showDialog ?? false)

const staticHtmlElements = computed(() => {
  return tm('dialog.terms-and-conditions.content') ?? [];
})
</script>
