<template>
  <div class="product-catalog-header">
    <h2 class="product-catalog-header__title">{{ $t('components.product-catalog-header.title') }}</h2>

    <div v-if="!productStore.loadingProducts" class="product-catalog-header__right">
      <div class="product-catalog-header__count hidden-xs-only">
        {{ $t('components.product-catalog-header.count-1') }}
        <strong>{{ productStore.currentPageStartIndex }} - {{ productStore.currentPageEndIndex }}</strong>
        {{ $t('components.product-catalog-header.count-2') }}
        <strong>{{ productStore.totalCount }} {{ $t('components.product-catalog-header.count-3', productStore.totalCount) }}</strong>
      </div>
      <product-catalog-filters />
      <product-catalog-search v-if="displaySearch" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useProductStore } from '@/store/product-store'
import ProductCatalogFilters from '@/components/ProductCatalogFilters.vue'
import ProductCatalogSearch from '@/components/ProductCatalogSearch.vue'
import { PRODUCTS_SEARCH_ENABLED } from '@/utils/featureToggle'

const productStore = useProductStore()

const displaySearch = computed(() => {
  return PRODUCTS_SEARCH_ENABLED()
})
</script>
