<template>
  <div class="product-catalog-item-wrapper">
    <div class="product-catalog-item" @click="navigateToProductDetails(id)">
      <div class="product-catalog-item__top">
        <product-tag v-if="false" type="" class="product-catalog-item__tag">
          {{ $t('components.product-catalog-item.promotion') }}
        </product-tag>
        <router-link
          :to="`/product/${id}`">
          <span class="sr-only">{{ $t('components.product-catalog-item.link-alt') }}</span>
          <product-image :attachments="attachments" :product-name="name" class="product-catalog-item__image" />
        </router-link>
        <product-category :product-family="family" :is-clickable="true" />
        <product-stock-status
          :inventory-level="inventoryLevel"
        />
        <h3 class="product-catalog-item__title">{{ name }}</h3>
      </div>
      <div class="product-catalog-item__bottom">
        <product-price
          :price="price"
          :is-on-sale="false">
        </product-price>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router'
import { navigateToProductDetails } from '@/router'
import ProductImage from '@/components/ProductImage.vue'
import ProductTag from '@/components/ProductTag.vue'
import ProductCategory from '@/components/ProductCategory.vue'
import ProductPrice from '@/components/ProductPrice.vue'
import ProductStockStatus from '@/components/ProductStockStatus.vue'
import { Attachment } from '@/domain/attachment'

const router = useRouter()

// Copied from : '@/src/domain/product-item'
// Issue : https://github.com/vuejs/core/issues/4294
type ProductItem = {
  id?: string,
  name?: string,
  description?: string,
  family?: string,
  attachments?: Array<Attachment>,
  price?: string,
  inventoryLevel?: string,
  pricebookEntryId?: string
}

const props = defineProps<ProductItem>()
</script>
