<template>
  <shop-header></shop-header>
  <el-main v-loading="productStore.loadingProduct" class="product-details" id="main-content" role="main" :aria-label="$t('accessibility.landmarks.main')">
    <template v-if="productStore.product && !failedFetchProduct">
      <div class="product-details__inner">
        <product-breadcrumb :productName="productName" />
        <div class="product-details__header">
          <product-details-images :attachments="product?.attachments" class="product-details__images" />
          <div class="product-details__info">
            <div class="product-details__info__top">
              <product-category :product-family="productFamily" :is-clickable="true" />
              <h2 class="product-details__name">{{ productName }}</h2>
              <p class="product-details__sku">{{ $t('components.product-details.sku') }}: {{ product?.id }}</p>
              <product-price
                :price="product?.price"
                :is-on-sale="false">
              </product-price>
              <free-kit-message v-if="isStarterKit" />
            </div>
            <product-stock-status
              :showNotifyButton="true"
              :inventory-level="product?.inventoryLevel"
              @open-lightbox-notify-email="toggleEmailDialogVisibility(true)"
            />
            <product-details-information />
            <starter-kit-message v-if="isModem" />
            <div class="product-details__add-cart">
              <product-quantity
                :quantity-selected="quantitySelected"
                :is-in-stock="isInStock"
                :show-label="true"
                @change="(newQuantity) => quantitySelected = newQuantity">
              </product-quantity>
            </div>
            <product-ctas :isMobile="true" :is-in-stock="isInStock" @buy-now="buyNow" @add-to-cart="addToCartAndShowRecommendations"></product-ctas>
          </div>
        </div>
      </div>
      <div class="product-details__tabs" id="product-details__tabs">
        <div class="product-details__inner">
          <div class="product-details__ctas">
            <product-ctas :isMobile="false" :is-in-stock="isInStock" @buy-now="buyNow" @add-to-cart="addToCartAndShowRecommendations"></product-ctas>
          </div>
          <product-details-tabs :active-tab="activeTab" />
        </div>
      </div>
      <product-details-suggestions />
      <dialog-addon-recommendations v-if="productStore.product?.recommendedAddons.length && dialogRecommendationsVisible" :is-buy-now="isBuyNow" :show-dialog="dialogRecommendationsVisible" @dialog-continue="onContinueButton(true)" @dialog-closed="onContinueButton(false)" @dialog-continue-to-cart="addAddonsToCart" @add-addons-to-cart="addAddonsToCart" @go-to-cart="goToCart"/>
    </template>
    <product-not-found-message v-else-if="failedFetchProduct" />
  </el-main>
</template>

<script setup lang="ts">
import { ref, onBeforeMount, watch, computed, h } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { useHead } from '@vueuse/head'
import { useProductStore } from '@/store/product-store'
import { useCartStore } from '@/store/cart-store'
import { navigateToCart, navigateToHome } from '@/router'
import { ElMessage } from 'element-plus'
import { generateCartItem } from '@/utils/cart'
import { blurActiveElement } from '@/utils/dom'
import { getStockStatus } from '@/utils/stock'
import { PRODUCT_TAB_NAMES, STOCK_STATUSES } from '@/utils/constants'
import { getTranslatedTextByKey } from '@/utils/i18n'
import ProductBreadcrumb from '@/components/ProductBreadcrumb.vue'
import ProductDetailsImages from '@/components/ProductDetailsImages.vue'
import ProductCategory from '@/components/ProductCategory.vue'
import ProductDetailsTabs from '@/components/ProductDetailsTabs.vue'
import ProductPrice from '@/components/ProductPrice.vue'
import ProductQuantity from '@/components/ProductQuantity.vue'
import ProductStockStatus from '@/components/ProductStockStatus.vue'
import ProductCtas from '@/components/ProductCtas.vue'
import ShopHeader from '@/components/ShopHeader.vue'
import DialogAddonRecommendations from '@/components/Dialog/DialogAddonRecommendations.vue'
import FreeKitMessage from '@/components/FreeKitMessage.vue'
import { EcLgCheckmarkCircle } from '@ec/icons'
import StarterKitMessage from "@/components/StarterKitMessage.vue";
import ProductDetailsSuggestions from "@/components/ProductDetailsSuggestions.vue";
import ProductNotFoundMessage from "@/components/ProductNotFoundMessage.vue";
import ProductDetailsInformation from "@/components/ProductDetailsInformation.vue";

const { t } = useI18n()
const route = useRoute()
const productStore = useProductStore()
const cartStore = useCartStore()

const quantitySelected = ref(1)
const activeTab = ref(PRODUCT_TAB_NAMES.DESCRIPTION)
const dialogEmailNotifyVisible = ref(false)
const dialogRecommendationsVisible = ref(false)
const successMessageInstance = ref()
const failedFetchProduct = ref(false)
let isBuyNow = ref(false)

const product = computed(() => {
  return productStore.product
})

const productName = computed(() => {
  return productStore.product?.name
})

const productDescription = computed(() => {
  return productStore.product?.description
})

const productFamily = computed(() => {
  return productStore.product?.family
})

const productInventoryLevel = computed(() => {
  return productStore.product?.inventoryLevel ?? ''
})

const isStarterKit = computed(() => {
  return productStore.product?.productAttributes?.some(attr => attr.label === 'IsStarterKit' && attr.value === 'true')
})

const isModem = computed(() => {
  return productStore.product?.productAttributes?.some(attr => attr.label === 'IsModem' && attr.value === 'true')
})

const isInStock = computed(() => {
  return getStockStatus(productInventoryLevel.value) !== STOCK_STATUSES.OUT_OF_STOCK
})

const productSuggestionList = computed(() => {
  return productStore.product?.suggestionList ?? []
})

const seoDescription = computed(() => productStore.product?.seoDescription);

const metaConfig = { // expose to unit tests
  title: getTranslatedTextByKey('seo.pages.product-details.title'),
  description: seoDescription
}

useHead({
  title: computed(() => productName.value ?? metaConfig.title),
  meta: [{
    name: 'description',
    content: metaConfig.description,
  }],
})

function addToCartAndShowRecommendations() {
  if (productStore.product) {
    if (!productStore.product.recommendedAddons?.length) {
      addToCart()
      createSuccessMessage(quantitySelected.value)
    }else {
      toggleRecommendationsDialogVisibility(true)
    }
  }
}

function buyNow() {
  if (productStore.product.recommendedAddons?.length) {
    isBuyNow.value = true;
    toggleRecommendationsDialogVisibility(true)
  }else if(productStore.product) {
    addToCart()
    createSuccessMessage(quantitySelected.value)
    navigateToCart()
  }
}

function addToCart() {
  const cartItem = generateCartItem(productStore.product, quantitySelected.value)
  cartStore.addItem(cartItem)
  blurActiveElement()
}

function createSuccessMessage(quantityAdded: number = 1) {
  successMessageInstance.value = ElMessage({
    type: 'success',
    message: h('div', { 'class': 'el-message__content el-message__content--space-between' }, [
      h('span', null, t('cart.added-to-cart-success', quantityAdded)),
      h('a', { 'id': 'goToCartLink' }, t('button.view-cart')),
    ]),
    zIndex: 3000, // on top of dialogs,
    icon: EcLgCheckmarkCircle,
    customClass: 'el-message--success',
    duration: 3000,
    showClose: true,
  })

  const goToCartLink = document.getElementById('goToCartLink');
  if (goToCartLink) {
    goToCartLink.onclick = () => {
      goToCart()
    }
  }
}

function closeSuccessMessage() {
  if (successMessageInstance.value && typeof successMessageInstance.value.close === 'function') {
    successMessageInstance.value.close()
  }
}

const doFetchProduct = async(productId: string) => {
  try {
    return productStore.fetchProduct(productId)
  } catch (error) {
    throw error
  }
}

async function fetchProductDetails() {
  failedFetchProduct.value = false
  if (!route.params?.id) {
    return navigateToHome()
  }

  try {
    await doFetchProduct(route.params.id.toString())
  } catch (error) {
    failedFetchProduct.value = true
  }
}

const toggleEmailDialogVisibility = (visible: boolean = false) => {
  dialogEmailNotifyVisible.value = visible;
  if (!visible) blurActiveElement()
}

const toggleRecommendationsDialogVisibility = (visible: boolean = false) => {
  dialogRecommendationsVisible.value = visible;
  if (!visible) blurActiveElement()
}

const onContinueButton = (isContinue: boolean = false) => {
  dialogRecommendationsVisible.value = false;
  blurActiveElement()

  if (isContinue) {
    addToCart();
    createSuccessMessage(quantitySelected.value)
  }
}


const addAddonsToCart = (isGoToCart: boolean, addonsList: any[]) => {
  if (addonsList.length) {
    closeSuccessMessage()
    let totalAddonsAddedToCart = addonsList.map(addonItem => addonItem.quantitySelected).reduce((a, b) => a + b, 0)
    totalAddonsAddedToCart += quantitySelected.value;
    addonsList.forEach((addonItem) => {
      const cartItem = generateCartItem(addonItem.recommendation, addonItem.quantitySelected)
      cartStore.addItem(cartItem)
    });
    const cartBaseItem = generateCartItem(productStore.product, quantitySelected.value)
    cartStore.addItem(cartBaseItem)

    createSuccessMessage(totalAddonsAddedToCart)
    toggleRecommendationsDialogVisibility(false)
  }

  if(isGoToCart) goToCart();
}

const goToCart = () => {
  closeSuccessMessage()
  navigateToCart()
}

onBeforeMount(async () => {
  fetchProductDetails()
})

watch(
  () => route.params.id,
  async (newId) => {
    if (newId) {
      quantitySelected.value = 1
      fetchProductDetails()
    }
  }
)
</script>
