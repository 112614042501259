<template>
  <div class="product-price" :class="{ 'product-price--is-small': isSmallLayout }">
    <div v-if="isOnSale" class="product-price__top">
      <div v-if="$i18n.locale === 'en'" class="product-price__price product-price__price--small product-price__price--striked">
        <span class="product-price__price__dollar-sign">$</span>
        <span>{{ priceTotalUnit }}</span>
        <span>.{{ priceTotalCent }}</span>
      </div>
      <div v-if="$i18n.locale === 'fr'" class="product-price__price product-price__price--small product-price__price--striked">
        <span>{{ priceTotalUnit }}</span>
        <span>,{{ priceTotalCent }}</span>
        <span class="product-price__price__dollar-sign">$</span>
      </div>
    </div>
    <div class="product-price__bottom">
      <div v-if="$i18n.locale === 'en'" class="product-price__price">
        <span class="product-price__price__dollar-sign">$</span>
        <span class="product-price__price__unit">{{ priceUnit }}</span>
        <span class="product-price__price__cent">.{{ priceCent }}</span>
      </div>
      <div v-if="$i18n.locale === 'fr'" class="product-price__price">
        <span class="product-price__price__unit">{{ priceUnit }}</span>
        <span class="product-price__price__cent">,{{ priceCent }}</span>
        <span class="product-price__price__dollar-sign">$</span>
      </div>
      <product-tag v-if="isOnSale" type="success" class="product-price product-tag--bold">
        {{ discountPercent }}<span>%</span>
        {{ $t('components.product-catalog-item.off') }}
      </product-tag>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import ProductTag from './ProductTag.vue'

type ProductPrice = {
  price: string,
  discount?: string,
  isOnSale?: boolean,
  isSmallLayout?: boolean,
}

const props = withDefaults(defineProps<ProductPrice>(), {
  discount: '0.0',
  isOnSale: false,
  isSmallLayout: false,
})

const priceUnit = computed(() => {
  return props.price && props.price !== '0.0'
    ? props.price.split('.')[0]
    : 'N/A'
})

const priceCent = computed(() => {
  return props.price && props.price !== '0.0'
    ? props.price.split('.').length === 2 ? props.price.split('.')[1].padEnd(2, '0') : '00'
    : '00'
})

const discountUnit = computed(() => {
  return props.discount && props.discount !== '0.0'
    ? props.discount.split('.')[0]
    : 'N/A'
})

const discountCent = computed(() => {
  return props.discount && props.discount !== '0.0'
    ? props.discount.split('.').length === 2 ? props.discount.split('.')[1].padEnd(2, '0') : '00'
    : '00'
})

const discountPercent = computed(() => {
  return (priceUnit.value !== 'N/A' && discountUnit.value !== 'N/A')
    ? Math.trunc(((Number(discountUnit.value) * 100) / Number(priceTotalUnit.value))).toString()
    : ''
})

const priceTotalUnit = computed(() => {
  return (priceUnit.value !== 'N/A' && discountUnit.value !== 'N/A')
    ? (Number(priceUnit.value) + Number(discountUnit.value)).toString()
    : ''
})

const priceTotalCent = computed(() => {
  return (priceCent.value !== '00' && discountCent.value !== '00')
    ? (Number(priceCent.value) + Number(discountCent.value)).toFixed(2).toString()
    : '00'
})
</script>
