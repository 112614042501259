import { Product } from '@/src/domain/product'
import { CartItem } from '@/domain/cart-item'
import { CheckoutItem } from '@/domain/checkout-item'
import { getAttachmentUrl, getDefaultAttachmentIndex } from '@/utils/attachment'

export const generateCartItem = (productItem: Product, quantitySelected: number = 0): CartItem => {
  return {
    id: productItem.id!,
    name: productItem.name!,
    description: productItem.description!,
    attachments: productItem.attachments!,
    price: productItem.price!,
    quantitySelected: quantitySelected,
    pricebookEntryId: productItem.pricebookEntryId!
  }
}

export const getCartItemById = (items: CartItem[], itemId: string) => {
  return items.find(item => item.id === itemId)
}

export const getFilteredItemsFromCheckout = (cartItems: CartItem[], checkoutItems: CheckoutItem[]) => {
  return cartItems.filter((cartItem: CartItem) => {
    return checkoutItems.some((checkoutItem: CheckoutItem) => checkoutItem.pricebookEntryId === cartItem.pricebookEntryId)
  }) ?? []
}

export const getDisplayUrlFromCartItem = (item: CartItem): string => {
  let displayUrl = ''
  if (item.attachments?.length) {
    const defaultIndex = getDefaultAttachmentIndex(item.attachments)
    displayUrl = getAttachmentUrl(item.attachments[defaultIndex].url)
  }
  return displayUrl
}
