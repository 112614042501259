import { createI18n } from 'vue-i18n'
import en from '@/i18n/en.json'
import fr from '@/i18n/fr.json'
import { getLanguage } from 'rky-library-ui'

const locale = getLanguage()

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  warnHtmlMessage: false,
  locale,
  messages: Object.assign({ en, fr })
})
export default i18n
