/* eslint-disable @typescript-eslint/no-explicit-any */
import '@/styles/index.scss'
import { createApp, h } from 'vue'
import App from '@/App.vue'
import ElementPlus from 'element-plus'
import router from '@/router'
import i18n from '@/i18n/i18n'
import { createHead } from '@vueuse/head'
import EcIcons from '@ec/icons'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'swiper/css'
import { Settings } from 'luxon'
import { getLanguage } from 'rky-library-ui'
import { createPinia } from 'pinia'
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core'
import { provideApolloClient } from '@vue/apollo-composable'
import { getImportMetaEnvProp } from '@/utils/esmHelpers'
import { Directive } from 'vue'
import { numberFormat as directiveNumberFormat } from "@/directives/numberFormat"
import VueMatomo from 'vue-matomo'
import axios from 'axios'
import axiosInterceptor from './api/interceptor/axios-interceptor'

Settings.defaultLocale = getLanguage()

const matomoSiteId = window.location.host === 'enterprisecentre.bell.ca' ? 4 : 1
const matomoSettings = {
  host: '/api/analytics',
  siteId: matomoSiteId,
  trackerFileName: 'matomo',
  router: router,
  enableLinkTracking: true,
  requireConsent: false,
  trackInitialView: true,
  disableCookies: false,
  enableHeartBeatTimer: false,
  heartBeatTimerInterval: 15,
  debug: process.env.NODE_ENV === 'development',
  userId: undefined,
  cookieDomain: undefined,
  domains: undefined,
  preInitActions: []
}

const httpLink = createHttpLink({
  uri: getImportMetaEnvProp('VITE_GRAPHQL_URI') as string
})

// Cache implementation
const cache = new InMemoryCache()

// Create the apollo client
const apolloClient = new ApolloClient({
  link: httpLink,
  cache
})

// Makes the client available thoughout the app, even outside setup functions
provideApolloClient(apolloClient)

const app = createApp({
  mounted () {
    let cacheBuster = '1.0.0'
    const timestamp = Math.floor(Date.now() / 1000)
    fetch('/rky-neo-form-lib/shop/cache-buster.txt?v=' + timestamp)
      .then(response => {
        if (response.ok) {
          return response.text()
        } else {
          return Promise.resolve(cacheBuster)
        }
      })
      .then(text => {
        cacheBuster = text
      }).finally(() => {
      const neoFormWebComponentScript = document.createElement('script')
      neoFormWebComponentScript.setAttribute('type', 'module')
      neoFormWebComponentScript.setAttribute('src', `/rky-neo-form-lib/shop/rky-neo-form-lib.js?v=${cacheBuster}`)
      document.head.appendChild(neoFormWebComponentScript)
    })

    // sidebar wc
    const webComponentScript = document.createElement('script')
    webComponentScript.setAttribute('src', '/wc/rky-wc-ec-sidebar.min.js')
    document.head.appendChild(webComponentScript)
  },
  render: () => h(App)
}).use(ElementPlus)
  .use(router)
  .use(createPinia())
  .use(i18n)
  .use(createHead())
  .use(EcIcons)
  .use(VueMatomo, matomoSettings)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app.directive("number-format", <Directive<HTMLElement,string>>directiveNumberFormat)

app.mount('#app')

// Add a request interceptor
axios.interceptors.request.use(
  (config: any) => config,
  (error: any) => Promise.reject(error)
)

// Add a response interceptor
axios.interceptors.response.use(
  (response: any) => response,
  (error: any) => axiosInterceptor(app, axios, error)
)

axios.defaults.timeout = 30000
