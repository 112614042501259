<template>
  <div v-if="attributes.length" class="product-attributes">
    <ul class="product-attributes__list">
      <li v-for="attribute in displayedProductAttributes" class="product-attributes__list-item">
        <strong v-html="formatAttributeLabel(attribute.label)" />
        {{ attribute.value }}
      </li>
    </ul>
  </div>

  <div v-if="attachments.length" class="product-attributes__attachments">
      <div v-for="attachment in displayedFileAttachments" class="product-attributes__attachments__list-item">
        <ec-xs-download class="product-attributes__attachments__icon" />
        <a :href="attachment.url">
          <strong>{{ $t('components.product-details.' + attachment.title) }}</strong>
        </a>
      </div>
  </div>
</template>

<script setup lang="ts">
import {computed} from 'vue'
import {useI18n} from 'vue-i18n'
import {ProductAttribute} from '@/domain/product-attribute'
import {Attachment} from "@/domain/attachment";
import {EcXsDownload} from "@ec/icons";

type ProductAttributes = {
  attributes: ProductAttribute[],
  attachments: Attachment[]
}

const props = withDefaults(defineProps<ProductAttributes>(), {
  attributes: [],
  attachments: []
})

const { locale } = useI18n({ useScope: 'global' })

const displayedProductAttributes = computed(() => {
  const allAttributes = props.attributes && props.attributes.length
    ? props.attributes.filter(attribute => attribute.showInMarketplace)
    : []
  return removeDuplicateAttributesByName(allAttributes)
})


const displayedFileAttachments = computed(() => {
  return props.attachments && props.attachments.length
      ? props.attachments.filter(attachment => attachment.contentType == 'File')
      : [];
})

const removeDuplicateAttributesByName = (attributes = []) => {
  const uniqueAttributes = []
  for (const attribute of attributes) {
    const isDuplicate = uniqueAttributes.find((uniqueAttribute) => uniqueAttribute.label === attribute.label)
    if (!isDuplicate) uniqueAttributes.push(attribute)
  }
  return uniqueAttributes
}

const formatAttributeLabel = (label = '') => {
  const labelSuffix = locale.value === 'en' ? ':' : ' :'
  return label + labelSuffix
}
</script>
