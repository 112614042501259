import { defineStore } from 'pinia'
import { getItemsSubtotal } from '@/utils/pricing'
import { getFilteredItemsFromCheckout, getCartItemById, getDisplayUrlFromCartItem } from '@/utils/cart'
import { removeItemByIndex } from '@/utils/array'
import { validatePriceCart } from '@/api/graph-ql/order-api'
import { PRODUCT_REMARK_VALUES } from '@/utils/constants'
import { CartState } from '@/store/types/cart-state'
import { CartItem, CartItemValidatePrice } from '@/domain/cart-item'
import { CheckoutItem } from '@/domain/checkout-item'
import { GqlValidPriceItem } from "@/domain/__generated__/graph-ql-types";

export const useCartStore = defineStore('cartStore', {
  state: () => ({
    items: JSON.parse(localStorage.getItem('ec-shop-cart') ?? '[]') ?? [],
    invalidItems: [],
    loadingValidatePrice: false,
    showSessionTimeoutMessage: false,
    showInvalidItemsMessage: false,
    canBypassInvalidItemsMessage: true,
  } as CartState),
  actions: {
    addItem(item: CartItem) {
      const existingItem = getCartItemById(this.items, item.id)
      if (existingItem) {
        if (existingItem.quantitySelected + item.quantitySelected >= 999) {
          existingItem.quantitySelected = 999
        } else {
          existingItem.quantitySelected += item.quantitySelected
        }
      } else {
        this.items.push(item)
      }
      this.updateStorage()
    },
    setItems(items: CartItem[]) {
      this.items = items
      this.updateStorage()
    },
    clearItems() {
      this.items = []
      this.updateStorage()
    },
    setItemsFromCheckout(checkoutItems: CheckoutItem[]) {
      const filteredCartItems = getFilteredItemsFromCheckout(JSON.parse(JSON.stringify(this.items)), checkoutItems)
      this.items = filteredCartItems
      this.updateStorage()
    },
    removeItem(itemId: string) {
      const indexToDelete = this.items.findIndex(i => i.id === itemId)
      if (indexToDelete !== -1) {
        this.items = removeItemByIndex(this.items, indexToDelete)
        this.updateStorage()
      }
    },
    updateItemQuantity(itemId: string, newQuantity: number) {
      const existingItem = getCartItemById(this.items, itemId)
      if (existingItem) {
        existingItem.quantitySelected = newQuantity
        this.updateStorage()
      }
    },
    setInvalidItems(invalidItems: CartItemValidatePrice[]) {
      this.invalidItems = invalidItems
    },
    clearInvalidItems() {
      this.invalidItems = []
    },
    async validateCart(): Promise<void> {
      if (this.items.length === 0) return Promise.resolve()

      this.setLoadingValidatePrice(true)
      this.clearInvalidItems()
      return new Promise<void>((resolve, reject) => {
        const GqlObject: any = {
          productPriceItems: getCartItemsToValidatePrice(this.items),
        }

        const { onDone, onError } = validatePriceCart(GqlObject)
        onDone((result: any) => {
          const { productPriceItems } = getOutOfStockItemsFromValidatePriceResponse(result)
          if (productPriceItems.length) {
            this.setInvalidItems(productPriceItems)
            this.setShowInvalidItemsMessage(true)
          }
          this.setLoadingValidatePrice(false)
          resolve()
        })

        onError((error: any) => {
          this.setLoadingValidatePrice(false)
          reject(error)
        })
      })
    },
    setLoadingValidatePrice(loadingValidatePrice: boolean) {
      this.loadingValidatePrice = loadingValidatePrice
    },
    setShowSessionTimeoutMessage(showSessionTimeoutMessage: boolean) {
      this.showSessionTimeoutMessage = showSessionTimeoutMessage
    },
    setShowInvalidItemsMessage(showInvalidItemsMessage: boolean) {
      this.showInvalidItemsMessage = showInvalidItemsMessage
    },
    setCanBypassInvalidItemsMessage(canBypassInvalidItemsMessage: boolean) {
      this.canBypassInvalidItemsMessage = canBypassInvalidItemsMessage
    },
    updateStorage() {
      localStorage.setItem('ec-shop-cart', JSON.stringify(this.items))
    }
  },
  getters: {
    count(state) {
      return state.items.map(item => item.quantitySelected).reduce((a, b) => a + b, 0)
    },
    subtotal(state) {
      return getItemsSubtotal(state.items, true)
    },
    getItemQuantityById: (state) => {
      return (itemId: string = '') => {
        const existingItem = getCartItemById(state.items, itemId)
        if (existingItem) return existingItem.quantitySelected
        return 0
      }
    },
    hasItems(state) {
      return state.items.length
    },
    hasInvalidItems(state) {
      return state.invalidItems.length
    },
  }
})

const getCartItemsToValidatePrice = (cartItems: CartItem[]): CartItemValidatePrice[] => {
  return cartItems.map((cartItem: CartItem) => {
    const item: CartItemValidatePrice = {
      pricebookEntryId: cartItem.pricebookEntryId,
      unitPrice: cartItem.price,
      quantity: cartItem.quantitySelected,
      name: cartItem.name,
      productId: cartItem.id,
      displayUrl: getDisplayUrlFromCartItem(cartItem),
    }
    return item
  })
}

const getOutOfStockItemsFromValidatePriceResponse = (result: any): { productPriceItems: CartItemValidatePrice[] } => {
  const responseValidatePrice = result?.data?.validatePriceCart
  const productPriceItems: CartItemValidatePrice[] = responseValidatePrice?.productPriceItems.filter((productPriceItem: GqlValidPriceItem) => {
    return productPriceItem.remark?.includes(PRODUCT_REMARK_VALUES.OUT_OF_STOCK)
  }).map((validPriceItem: GqlValidPriceItem) => {
    const item: CartItemValidatePrice = {
      pricebookEntryId: validPriceItem.pricebookEntryId ?? '',
      unitPrice: validPriceItem.unitPrice?.toString() ?? '',
      quantity: validPriceItem.quantity ?? 1,
      name: validPriceItem.name ?? '',
      productId: validPriceItem.productId ?? '',
      displayUrl: validPriceItem.displayUrl ?? '',
    }
    return item
  }) ?? []
  return { productPriceItems }
}
